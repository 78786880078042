// src/components/FAQ/FAQ.tsx
import React, { useState } from "react";
import { CardContent, Typography, Box, Tab } from "@mui/material";
import { Help as HelpIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { FaqCategory } from "@components/FAQ/types";
import { StyledCard, StyledHeader, StyledTabs } from "./styles";
import { faqData } from "./FaqData";
import FaqAccordion from "./FaqAccordion";

const FAQ: React.FC = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  
  

  return (
    <StyledCard>
      <StyledHeader>
      <HelpIcon 
        sx={{
            fontSize: 28,
            color: 'primary.main',
            '&:hover, &:focus, &:active': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            outline: 'none',
            }
        }}
        />
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('자주 묻는 질문')}
        </Typography>
      </StyledHeader>

      <CardContent sx={{ pt: 0 }}>
        <Box sx={{ mb: 4 }}>
          <StyledTabs 
            value={selectedTab} 
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {faqData.map((category, index) => (
              <Tab key={index} label={t(category.category)} />
            ))}
          </StyledTabs>
        </Box>

        {faqData[selectedTab].items.map((item, index) => (
          <FaqAccordion key={index} item={item} />
        ))}
      </CardContent>
    </StyledCard>

    
  );
};


export default FAQ;