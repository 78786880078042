//src/components/ChatRoom/MessageDisplay.tsx
import React, { useEffect, useState, useRef, useCallback, useMemo} from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import './MessageDisplay.css';
import MessageRow from './MessageRow';
import axios from 'axios';
import NewMessagePopup from './NewMessagePopup';
import useChatStore from '@store/chatStore';
import useSocketStore from '@store/socketStore';


const MessageDisplay: React.FC = () => {
  const { socket } = useSocketStore(); 
  const currentRoomId = useChatStore((state) => state.currentRoomId);
  const messages = useChatStore((state) => state.messages);

  const [lastReadIndex, setLastReadIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [newMessageCount, setNewMessageCount] = useState<number>(0);
  const [visibilityRatio, setVisibilityRatio] = useState<number>(0);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const prevMessagesLengthRef = useRef<number>(messages.length);
  const memoizedMessages = useMemo(() => messages, [messages]);

  
  const fetchLastReadMessageId = async (): Promise<number | undefined> => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/chat/lastReadMessageID`,
        {
          params: { currentRoomId },
          withCredentials: true
        }
      );

      const newLastReadMessageIndex = response.data.last_read_message_index;
      if (response.status === 200 && newLastReadMessageIndex !== undefined) {
        setLastReadIndex(newLastReadMessageIndex);
        console.log('Last read message index:', newLastReadMessageIndex);
        return newLastReadMessageIndex; 
      } else {
        console.log('Last read message Index not found in messages array');
        return undefined;
      }
    } catch (error) {
      console.error('Failed to fetch last read message ID:', error);
      return undefined;
    }
  };

  useEffect(() => {
    setLoading(true); 
    fetchLastReadMessageId().then(newLastReadMessageIndex => {
      if (newLastReadMessageIndex === undefined) {
        console.log('Failed to fetch last read message index');
      } else {
        const checkAndScrollToIndex = () => {
          if (virtuosoRef.current) {
            console.log('Scrolling to index:', newLastReadMessageIndex);
            virtuosoRef.current.scrollToIndex({
              index: newLastReadMessageIndex,
              align: 'end',
              behavior: 'auto'
            });
          } else {
            setTimeout(checkAndScrollToIndex, 100);
          }
        };
        checkAndScrollToIndex();
      }
      setLoading(false);
    });
  }, [currentRoomId]);
  
  const handleScrollToBottom = () => {
    if (virtuosoRef.current) {
      console.log('Scrolling to bottom', { messagesLength: messages.length });
      virtuosoRef.current.scrollToIndex({
        index: messages.length - 1,
        align: 'end',
        behavior: 'smooth',
      });
      setNewMessageCount(0);
    }
  };

  const handleEndReached = () => {
    // if (visibilityRatio > 0.2) 
      {  // Add visibilityRatio condition here
    setNewMessageCount(0);
    }
  };


    useEffect(() => {
    if (prevMessagesLengthRef.current < messages.length) {
      setNewMessageCount(prev => prev + (messages.length - prevMessagesLengthRef.current));
    }
    prevMessagesLengthRef.current = messages.length;
  }, [messages]);


  const handleVisibilityChange = useCallback(
    (messageId: number | undefined, visibilityRatio: number) => {
      setVisibilityRatio(visibilityRatio);
    },
    [setVisibilityRatio]
  );
  


  const itemContent = useCallback(
    (_: number, message: any) => (
      <MessageRow
        message={message}
        onVisibilityChange={handleVisibilityChange}
      />
    ),
    [handleVisibilityChange]
  );
  
  
  if (loading) {
    return <div>Loading...</div>; 
  }
  if (!messages || messages.length === 0) {
    return <div className="no-messages">No messages to display</div>;
  }

  return (
    <div>
      <Virtuoso
        ref={virtuosoRef}
        data={memoizedMessages}
        itemContent={itemContent}
        overscan={20}
        followOutput={true}
        alignToBottom
        atBottomThreshold={2}
        endReached={handleEndReached}
        rangeChanged={(range) => {
          const lastVisibleIndex = range.endIndex;
          if (lastVisibleIndex > lastReadIndex && visibilityRatio === 1) {
            setLastReadIndex(lastVisibleIndex);
            console.log('%cEmitting last_read_message', 'color:red;', {currentRoomId, last_message_index: lastVisibleIndex });
            socket!.emit('last_read_message_index_update', {
              room_id: currentRoomId,
              last_message_index: lastVisibleIndex,
            });
          }
        }}
      />
      {newMessageCount > 0 && (
        <NewMessagePopup newMessageCount={newMessageCount} onClick={handleScrollToBottom} />
      )}
    </div>

  );
};


export default React.memo(MessageDisplay);
