// src/components/post/CommentWrite.tsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '@utils/axiosInstance';

import './CommentWrite.css';

interface CommentWriteProps {
  postId: number;
  onCommentAdded: () => void; 
}

const CommentWrite: React.FC<CommentWriteProps> = ({ postId, onCommentAdded }) => {
  const [contents, setContents] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();


  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      await axiosInstance.post(`/api/posts/${postId}/comments`, { contents });
      setContents('');
      setError(null);
      onCommentAdded(); 
    } catch (err) {
      setError(t('Failed to submit comment.'));
    }
  };

  return (
    <div className="comment-write">
      <h3>{t('Write a Comment')}</h3>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
        <label htmlFor="contents">{t('Content')}:</label>
        <textarea
            id="contents"
            value={contents}
            onChange={(e) => setContents(e.target.value)}
          />
        </div>
        <button type="submit">{t('Submit Comment')}</button>
      </form>
    </div>
  );
};

export default CommentWrite;
