// src/components/ChatRoom/ChatContainer.tsx
import React from 'react';
import MessageInput from './MessageInput';
import MessageDisplay from './MessageDisplay';
import './ChatContainer.css';


const ChatContainer: React.FC = () => {
  return (
    <div className="chat-container">
      <MessageDisplay />
      <MessageInput />
    </div>
  );
};



export default ChatContainer;

