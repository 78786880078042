import React from 'react';
import './NewMessagePopup.css';

interface NewMessagePopupProps {
  newMessageCount: number;
  onClick: () => void;
}

const NewMessagePopup: React.FC<NewMessagePopupProps> = ({ newMessageCount, onClick }) => {
  return (
    <div className="new-message-popup" onClick={onClick}>
      {newMessageCount} new message{newMessageCount > 1 ? 's' : ''}. Click to view.
    </div>
  );
};

export default NewMessagePopup;
