// src/components/SSPlace/styles.ts
import { styled } from '@mui/material';
import { Button, Card, IconButton } from '@mui/material';

export const StyledBannerCard = styled(Card)({
  position: 'relative',
  height: '400px',
  borderRadius: '20px',
  overflow: 'hidden',
});

export const BannerButton = styled(IconButton)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
});

export const CategoryButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  borderRadius: '25px',
  padding: '8px 20px',
  backgroundColor: active ? theme.palette.primary.main : 'transparent',
  color: active ? '#fff' : theme.palette.text.primary,
  border: active ? 'none' : '1px solid #e0e0e0',
  '&:hover': {
    backgroundColor: active ? theme.palette.primary.dark : 'rgba(0, 0, 0, 0.04)',
  },
}));

export const StyledCard = styled(Card)({
  height: '100%',
  borderRadius: '16px',
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
});