// src/components/TMM/components/Banner.tsx
import React from 'react';
import { Box, CardMedia, Typography, IconButton } from '@mui/material';
import { ArrowForwardIos as ArrowForwardIcon, ArrowBackIos as ArrowBackIcon } from '@mui/icons-material';
import { BannerProps } from '../types';
import { styled } from '@mui/material/styles';

const BannerContainer = styled('div')({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '20px',
  height: 400,
});

const SlideContainer = styled('div')<{ translateX: number }>(({ translateX }) => ({
  display: 'flex',
  transition: 'transform 0.5s ease-in-out',
  transform: `translateX(-${translateX}%)`,
}));

const BannerSlide = styled('div')({
  flex: '0 0 100%',
  position: 'relative',
});

const NavigationButton = styled(IconButton)<{ position: 'left' | 'right' }>(
  ({ position }) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    ...(position === 'left' ? { left: 20 } : { right: 20 }),
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
    zIndex: 2,
  })
);

const BannerContent = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '2rem',
  background: 'linear-gradient(transparent, rgba(0,0,0,0.7))',
  color: 'white',
});

const BannerIndicators = styled('div')({
  position: 'absolute',
  bottom: 20,
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  gap: '8px',
  zIndex: 2,
});

const Indicator = styled('div')<{ active: boolean }>(({ active }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: active ? 'white' : 'rgba(255, 255, 255, 0.5)',
  transition: 'all 0.3s ease',
  cursor: 'pointer',
}));

export const Banner: React.FC<BannerProps> = ({
  currentBanner,
  banners,
  onPrevClick,
  onNextClick,
  onBannerClick,
}) => (
  <BannerContainer>
    <SlideContainer translateX={currentBanner * 100}>
      {banners.map((banner, index) => (
        <BannerSlide key={banner.id}>
          <CardMedia
            component="img"
            image={banner.image}
            alt={banner.title}
            sx={{
              width: '100%',
              height: '400px',
              objectFit: 'contain',
            }}
          />
          <BannerContent>
            <Typography variant="h4" gutterBottom fontWeight="bold">
              {banner.title}
            </Typography>
            <Typography variant="subtitle1">
              {banner.subtitle}
            </Typography>
          </BannerContent>
        </BannerSlide>
      ))}
    </SlideContainer>

    <NavigationButton position="left" onClick={onPrevClick}>
      <ArrowBackIcon />
    </NavigationButton>
    <NavigationButton position="right" onClick={onNextClick}>
      <ArrowForwardIcon />
    </NavigationButton>

    <BannerIndicators>
      {banners.map((_, index) => (
        <Indicator
          key={index}
          active={currentBanner === index}
          onClick={() => onBannerClick(index)}
        />
      ))}
    </BannerIndicators>
  </BannerContainer>
);