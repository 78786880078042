// src/components/Sidebar.tsx
import React from 'react';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import useAuthStore from '@store/authStore';
import { useTranslation } from 'react-i18next';
import {
  AccountCircle as AccountCircleIcon,
  Forum as ForumIcon,
  HelpOutline as HelpOutlineIcon,
  ContactSupport as ContactSupportIcon,
  Chat as ChatIcon,
  Store as StoreIcon,
} from '@mui/icons-material';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user } = useAuthStore();
  const { t } = useTranslation();

  const menuItems = [
    {
      text: isAuthenticated ? user?.username : t('Profile'),
      icon: <AccountCircleIcon />,
      onClick: () => navigate('/profile'),
      link: '/profile',
    },
    {
      text: t('Community'),
      icon: <ForumIcon />,
      link: '/community',
    },
    {
      text: t('FAQ'),
      icon: <HelpOutlineIcon />,
      link: '/faq',
    },
    
    {
      text: t('Link'),
      icon: <ChatIcon />,
      link: '/link',
    },
    {
      text: t('Support'),
      icon: <ContactSupportIcon />,
      link: '/support',
    },
    {
      text: t('TMM'),
      icon: <StoreIcon />,
      link: '/tmm',
    },
  ];

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        background: '#000',
        width: '14rem',
        padding: '2rem 1.5rem',
        height: '100vh',
        color: '#fff',
        boxSizing: 'border-box',
        overflowY: 'auto',
        borderRight: '1px solid rgba(255, 255, 255, 0.05)',
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '3px',
        },
      }}
    >
      <List sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {menuItems.map((item, index) => {
          const isActive = location.pathname === item.link;
          return (
            <ListItemButton
              key={index}
              component={item.onClick ? 'div' : RouterLink}
              to={item.link}
              onClick={item.onClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '0.75rem 1rem',
                borderRadius: '12px',
                backgroundColor: isActive ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                transition: 'all 0.3s ease',
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  top: '50%',
                  height: isActive ? '70%' : '0%',
                  width: '4px',
                  backgroundColor: '#fff',
                  borderRadius: '0 4px 4px 0',
                  transform: 'translateY(-50%)',
                  transition: 'height 0.3s ease',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: isActive ? '#fff' : 'rgba(255, 255, 255, 0.7)',
                  minWidth: 'unset',
                  marginRight: '1rem',
                  transition: 'all 0.3s ease',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '0.95rem',
                      fontWeight: isActive ? 600 : 400,
                      color: isActive ? '#fff' : 'rgba(255, 255, 255, 0.7)',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.text}
                  </Typography>
                }
              />
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
};

export default Sidebar;