// src/components/Login/LoginModal.tsx
import React, { useState, FormEvent } from 'react';
import useAuthStore from '@store/authStore';
import Modal from './Modal';
import { useTranslation } from 'react-i18next'; 
import { TextField, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface LoginModalProps {
  show: boolean;
  onClose: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ show, onClose }) => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const { setAuthState } = useAuthStore();
  const { t } = useTranslation(); 
  const navigate = useNavigate(); 
  
  const handleSignUpClick = () => {
    onClose(); 
    navigate('/register'); 
  };

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null); 
    try {
      console.log('Trying username:', username);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/login/`, {
        username,
        password
      }, {
          withCredentials: true
      });

      console.log('Response:', response);
      
      if (response.status === 200) {
        const data = response.data;
        setAuthState({
          isAuthenticated: true,
          user: data.user,
        });
        onClose();
        navigate('/community'); 
      } else {
        setError(response.data.error || t('Login failed'));
      }
    } catch (error) {
      setError(t('Network error occurred'));
      console.error('Error during login:', error);
    }
  };
  
  return (
    <Modal show={show} onClose={onClose}>
      <Box component="form" onSubmit={handleLogin} sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt:2 }}>
        <TextField
          label={t('Username')}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          required
          variant="outlined"
          sx={{ bgcolor: 'white', borderRadius: 1, }}
        />
        
        <TextField
          label={t('Password')}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          required
          variant="outlined"
          sx={{ bgcolor: 'white', borderRadius: 1 }}
        />

        {error && (
          <Typography color="error" variant="body2" textAlign="center">
            {error}
          </Typography>
        )}

        <Button type="submit" variant="contained" fullWidth sx={{
          bgcolor: '#061E30', color: 'white', '&:hover': { bgcolor: '#051825' }
        }}>
          {t('Log In')}
        </Button>

        <Button variant="text" fullWidth onClick={handleSignUpClick} sx={{ color: '#061E30' }}>
          {t('Sign Up')}
        </Button>
      </Box>
    </Modal>
  );
};

export default LoginModal;
