//src/components/TMM/Map/Maps.tsx

import React, { useCallback, useRef, useState } from 'react';
import MapContainer from './MapContainer';
import CurrentLocationButton from './CurrentLocationButton';
import MarkersContainer from './MarkersContainer';
import Modal from './Modal';
import Marker from './Marker';
import { Data } from './Data/datas';

const Maps: React.FC = () => {
  const mapRef = useRef<naver.maps.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<{ lat: number; lng: number } | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<Data | null>(null);

  const handleMapLoad = useCallback((mapInstance: naver.maps.Map) => {
    mapRef.current = mapInstance;
    setMapLoaded(true);
  }, []);

  const handleCurrentLocation = useCallback(() => {
    const mapInstance = mapRef.current;
    if (!mapInstance) return;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude: lat, longitude: lng } = position.coords;
          const newLocation = { lat, lng };
          setCurrentLocation(newLocation); 
          mapInstance.setCenter(new window.naver.maps.LatLng(lat, lng));
          mapInstance.setZoom(14);
        },
        (error) => {
          console.error('현재 위치를 가져오는 데 실패했습니다.', error);
          alert('현재 위치를 가져오는 데 실패했습니다.');
        }
      );
    } else {
      console.warn('Geolocation을 지원하지 않는 브라우저입니다.');
      alert('Geolocation을 지원하지 않는 브라우저입니다.');
    }
  }, []);


  const handleMarkerClick = useCallback((data: Data) => {
    if (selectedData && selectedData.id === data.id) {
      // If the same marker is clicked again, toggle the modal
      setIsModalOpen(!isModalOpen);
      if (isModalOpen) {
        setSelectedData(null);
      }
    } else {
      setSelectedData(data);
      setIsModalOpen(true);
    }
  }, [isModalOpen, selectedData]);

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedData(null);
  };




  return (
    <div>
      <h1>Naver Maps with React</h1>
      <div style={{ marginBottom: '20px' }}>
        <CurrentLocationButton onClick={handleCurrentLocation} />
      </div>
      <MapContainer onMapLoad={handleMapLoad} />
      {mapLoaded && (
        <>
          <MarkersContainer map={mapRef.current!} onMarkerClick={handleMarkerClick} />
          
          {currentLocation && (
            <Marker
              map={mapRef.current!}
              position={currentLocation}
              title="현재 위치"
              icon={{
                content: `
                  <div style="
                    width: 24px;
                    height: 24px;
                    background: #4A90E2;
                    border: 2px solid white;
                    border-radius: 50%;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    font-size: 14px;
                  ">
                    📍
                  </div>
                `,
                anchor: new window.naver.maps.Point(12, 12),
              }}
            />
          )}
        </>
      )}
      {isModalOpen && selectedData && (
        <Modal onClose={closeModal} data={selectedData} />
      )}

      </div>
  );
};

export default Maps;
