// src/components/ChatRoom/ChatRoom.tsx
import React, { useEffect } from 'react';
import useChatStore from '@store/chatStore'; // Zustand auth store
import useSocketStore from '@store/socketStore';

import UsernameInput from './UsernameInput';
import RoomsList from './RoomsList';
import ChatContainer from './ChatContainer';
import './ChatRoom.css';
import { Room, Message } from './types';


const ChatRoom: React.FC = () => {


  const chatUsername = useChatStore(state => state.chatUsername);
  const setChatUsername = useChatStore(state => state.setChatUsername);
  const currentRoomId = useChatStore(state => state.currentRoomId);
  const setRooms = useChatStore(state => state.setRooms);
  const isLoadingRooms = useChatStore(state => state.isLoadingRooms);
  const setIsLoadingRooms = useChatStore(state => state.setIsLoadingRooms);
  const isMessagesLoaded = useChatStore(state => state.isMessagesLoaded);
  const setIsMessagesLoaded = useChatStore(state => state.setIsMessagesLoaded);
  const setMessages = useChatStore(state => state.setMessages);
  const addMessage = useChatStore(state => state.addMessage);

  const { initializeSocket } = useSocketStore();
  const socket = initializeSocket(); // Initialize socket here


  useEffect(() => {
    
    const handleUpdateRooms = (rooms: Room[]) => {
      setRooms(rooms);
      setIsLoadingRooms(false);
    };
    socket!.emit('request_rooms');
    socket!.on('update_rooms', handleUpdateRooms);
    return () => {
      socket!.off('update_rooms', handleUpdateRooms);
    };
  }, [socket, setRooms, setIsLoadingRooms]);

  useEffect(() => {
    if (currentRoomId !== null) {
      socket!.emit('join', { chatUsername, room: currentRoomId });
      socket!.emit('request_messages', { room: currentRoomId });

      const handleMessages = (fetchedMessages: any) => {
        setMessages(fetchedMessages);
        setIsMessagesLoaded(true);
      };
      const handleNewMessage = (newMessage: Message) => {
        addMessage(newMessage);
      };

      socket!.on('messages', handleMessages);
      socket!.on('receive_message', handleNewMessage);

      return () => {
        socket!.emit('leave', { chatUsername, room: currentRoomId });
        socket!.off('messages', handleMessages);
        socket!.off('receive_message', handleNewMessage);
      };
    }
  }, [currentRoomId, socket, chatUsername, setMessages, setIsMessagesLoaded,addMessage,
  ]);

  return (
    <div className="chat-app">
      <UsernameInput chatUsername={chatUsername} setChatUsername={setChatUsername} />
      {isLoadingRooms ? (
        <p>Loading rooms...</p>
      ) : (
        <RoomsList />
      )}
      {currentRoomId !== null && isMessagesLoaded && <ChatContainer />}
    </div>
  );
};

export default ChatRoom;
