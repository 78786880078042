//src/components/ChatRoom/VisibilityWrapper.tsx

import React, { useEffect, useRef } from 'react';

interface VisibilityWrapperProps {
  children: React.ReactNode;
  onVisibilityChange: (ratio: number) => void;
}

const VisibilityWrapper: React.FC<VisibilityWrapperProps> = ({ children, onVisibilityChange }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          onVisibilityChange(entry.intersectionRatio);
          // console.log('Intersection ratio:', entry.intersectionRatio);
        });
      },
      { threshold: [0, 0.2, 0.4, 0.6, 0.8, 1] }
    );

    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }
  
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [onVisibilityChange]);
  

  return <div ref={ref}>{children}</div>;
};

export default VisibilityWrapper;

