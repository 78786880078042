// Comment.tsx
import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { formatDate } from '@utils/formatDate';

interface CommentType {
  id: number;
  username: string;
  contents: string;
  created_at: string;
}

const Comment: React.FC<{ comment: CommentType }> = ({ comment }) => {
  const formattedCreatedAt= formatDate(comment.created_at);
  return (
    <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="subtitle2" color="text.primary" sx={{ fontWeight: 'bold' }}>
          {comment.username}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {formattedCreatedAt}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', color: 'text.secondary' }}>
        {comment.contents}
      </Typography>
    </Paper>
  );
};


export default Comment;
