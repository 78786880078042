// src/components/post/Post.tsx

import React, { memo, useCallback } from 'react';
import './Post.css';
import { Link } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { formatDate } from '@utils/formatDate';
import { PostType } from './types';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardHeader, Typography, IconButton, Chip} from '@mui/material';
import colors from '@styles/colors';


interface PostProps extends PostType {}
const Post: React.FC<PostProps> = ({
  id, username, category, title, contents, likes, comments_count, date, translations, hashtags }) => {
  const { t } = useTranslation();
  const formattedDate = formatDate(date);
  const heartColor = useCallback(() => getHeartColor(likes), [likes]);

  return (
    <Card sx={{ 
      borderRadius: 1, 
      border:'0px', 
      transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out', 
      '&:hover': { boxShadow: 6 } 
    }}>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Chip
                label={t(`PostList.${category}`)}
                sx={{
                  height: '2rem',
                  borderRadius: '2px',
                  marginRight: '10px',
                  fontSize: '0.6em',
                  backgroundColor: colors.mainColor,
                  color: colors.backgroundLight
                }}
              />
              <Link to={`/post/${id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                <Typography  component="div" sx={{ display: 'inline-flex', alignItems: 'center',
                  fontSize: '1em', fontWeight: 500,
                 }}>
                  {title} <span style={{ fontSize: '0.5em', marginLeft: '8px' }}>[{comments_count}]</span>
                </Typography>
              </Link>
            </Box>
            <Typography variant="body2" color="colors.textSecondary" sx={{ ml: 2 }}>
              {formattedDate}
            </Typography>
          </Box>
        }

        subheader={
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', 
            mt:0.5,
           }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton aria-label="likes">
                <FavoriteIcon sx={{ color: heartColor }}  fontSize="small" />
              </IconButton>
              <Typography variant="body2" color="text.secondary" sx={{  }}>
                {likes}
              </Typography>
            </Box>

            <Typography variant="body1" color="text.primary" sx={{ flex: 1, ml: 2, color:'#969696' }}>
              {contents}
            </Typography>

            <Typography variant="body2" color="text.secondary" sx={{ }}>
              {username}
            </Typography>
          </Box>
        }
        />
    </Card>
  );
};

export default memo(Post);

const getHeartColor = (likes: number): string => {
  const maxLikes = 100; 
  const intensity = Math.min(likes / maxLikes, 1); 
  const redValue = Math.floor(intensity * 255); 
  return `rgb(${redValue}, 0, 0)`; 
};
