//src/app/ProtectedRoute.tsx
import React, { useEffect, memo } from 'react';
import { Navigate } from 'react-router-dom';
import useAuthStore from '@store/authStore';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, validateAuthToken } = useAuthStore();
  useEffect(() => {
    if (!isAuthenticated) {
      validateAuthToken();
    }
  }, [isAuthenticated, validateAuthToken]);


  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default memo(ProtectedRoute);
