// src/styles/colors.ts
const colors = {
  // Primary colors
  mainColor: '#061E30',           // Deep navy blue (original)
  secondaryColor: '#0A2942',      // Slightly lighter navy
  accentColor: '#0F3B5C',         // Brighter navy for hover states
  
  // Text colors
  textPrimary: '#1f2937',         // Dark gray for primary text
  textSecondary: '#6b7280',       // Medium gray for secondary text
  
  // Background colors
  backgroundLight: '#f8fafc',     // Very light blue-gray
  backgroundCard: '#ffffff',      // Pure white for cards
  
  // Status colors
  errorColor: '#dc2626',          // Dark red for errors
  successColor: '#059669',        // Dark green for success
  
  // Border colors
  borderLight: '#e2e8f0',         // Light gray for borders
  
  // Gradient
  gradient: {
    start: '#061E30',
    end: '#0F3B5C',
  },
  postDetail:{
    mainColor:'#111'
  }
};


export default colors;
