//src/components/TMM/Map/MapContainer.tsx
import React, { useEffect, useRef } from 'react';
import { loadNaverMaps } from './utils/loadNaverMaps';

interface MapContainerProps {
    onMapLoad?: (map: naver.maps.Map) => void;
  }
  
  const MapContainer: React.FC<MapContainerProps> = ({ onMapLoad }) => {
    const mapRef = useRef<HTMLDivElement>(null);
    const mapInstanceRef = useRef<naver.maps.Map | null>(null);
  
    useEffect(() => {
      let isMounted = true;
  
      loadNaverMaps()
        .then(() => {
          if (isMounted && window.naver && window.naver.maps && mapRef.current) {
            const universityPosition = new window.naver.maps.LatLng(37.496799, 126.957146);
            const mapOptions: naver.maps.MapOptions = {
              center: universityPosition,
              zoom: 16,
            };
            const mapInstance = new window.naver.maps.Map(mapRef.current, mapOptions);
            mapInstanceRef.current = mapInstance;
            onMapLoad && onMapLoad(mapInstance);
          }
        })
        .catch((error) => {
          console.error(error);
        });
  
      return () => {
        isMounted = false;
        if (mapInstanceRef.current) {
            mapInstanceRef.current = null;
            }
      };
    }, [onMapLoad]);
  
    return (
      <div
        ref={mapRef}
        style={{ 
          width: '100%', 
          height: '500px', 
          border: '1px solid #ccc',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      ></div>
    );
  };
  
  export default MapContainer;
  