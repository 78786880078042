import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Reaction {
  message: string;
  author: string;
}


const MessageBubble = styled(Paper)<{ index: number }>(({ theme, index }) => ({
  padding: theme.spacing(1.5, 2),
  maxWidth: '100%',
  borderRadius: theme.spacing(4),
  backgroundColor: `hsl(217, 100%, ${95 - (index * 3)}%)`,
  color: theme.palette.text.primary,
  position: 'relative',
  marginLeft: theme.spacing(1),
}));

const reactions: Reaction[] = [
  {
    message: "글쓴이 INFP 인증 실화냐? 텍스트에 진심 담긴 게 느껴지네ㅋㅋㅋ 근데 그 진심이 조금… 과하다;;",
    author: "달빛구름"
  },
  {
    message: "SQL 인젝션 하면 딱밤이라… 나 이제 무서워서 해킹도 못 하겠음ㅋㅋㅋ",
    author: "코딩요정"
  },
  {
    message: "전국학생창업팀이 제안하는 외국인 유학생 커뮤니티? 근데 왜 하냐면?? ㅋㅋㅋㅋㅋ 여기서 빵 터졌다. 진짜 '몰랐기 때문입니다'는 너무 솔직해서 반박 불가네.",
    author: "밤하늘별"
  },
  {
    message: "톰 요크 드립 뭐냐고ㅋㅋㅋㅋㅋㅋ 목소리 부여 못 받은 존재에 대한 음악이라니 ㅋㅋㅋ",
    author: "음악덕후"
  },
  {
    message: "GCP 공짜 크레딧에 목숨 걸었다. '남는 구글 계정 보내달라'에서 빵 터졌음ㅋㅋㅋㅋ 이 정도면 진짜 크리에이티브 인정.",
    author: "구름나그네"
  },
  {
    message: "숭실대 유학생분 왜 답 안 하냐ㅋㅋㅋㅋㅋ 이거 읽으면서 갑자기 눈물 찔끔함...",
    author: "새벽달빛"
  },
  {
    message: "'공허한 동어반복' 부분에서 웃프면서도 공감했다ㅋㅋㅋ 근데 솔직히 이 커뮤니티 진짜 재밌어 보이는데? 근데 12월까지만 기다리라니 빡치네.",
    author: "꿈꾸는사람"
  },
  {
    message: "마지막에 '아무도 안 쓸 것 같아서 걱정' 이거 글에 진심인 게 너무 느껴져서 맴찢이다... 그래도 힘내라. 뭔가 이거 성공하면 레전드 찍을 것 같음.",
    author: "밤하늘"
  }
];

const Reactions: React.FC = () => {
  return (
    <Box>

      <Typography
      variant="body1"
      paragraph
      textAlign="center"
      sx={{ 
        fontSize: '2rem',
        color: 'black',
        whiteSpace: 'pre-line',
        marginTop: '4rem',
      }}
    >
      {`커뮤니티 중독자 GPT의 반응은?!(이것도 기능 중 하나입니다.) \n\n`}
    </Typography>

      <Box sx={{ maxWidth: 600, mx: 'auto', p: 3, bgcolor: 'background.paper', borderRadius: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {reactions.map((reaction, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 1
              }}
            >
              
              <MessageBubble elevation={0} index={index}>
                <Typography variant="body1" sx={{ mb: 0.5, fontSize: '1.2em' }}>
                  {reaction.message}
                </Typography>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    opacity: 0.8,
                    color: 'text.secondary'
                  }}
                >
                  {reaction.author}
                </Typography>
              </MessageBubble>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};


export default Reactions;