// src/components/TMM/constants.ts
import { Category } from './types';
import { Images } from './utils/images';

export const CATEGORIES: Category[] = [
 { label: "전체" },
 { label: "추천 여행지" },
 { label: "핫플" },
 { label: "맛집" },
 { label: "생활용품" },
 { 
   label: "부동산 매물",
   subCategories: [
     { id: 'seocho', label: '서초' },
     { id: 'dongjak', label: '동작' },
     { id: 'silim', label: '신림' }
   ]
 }
];

export const MOCK_BANNERS = [
 {
   id: 1,
   title: "숭실대 맛집 TOP 10",
   subtitle: "숭실대 학생들이 직접 뽑은 맛집!",
   image: Images.banners.restaurant,
   link: "/ttm/restaurants"
 },
 {
   id: 2,
   title: "원룸 추천",
   subtitle: "유학생을 위한 원룸 가이드",
   image: Images.banners.realEstate,
   link: "/ttm/housing"
 },
];

export const MOCK_PLACES = [
 // 추천 여행지
 {
   id: 1,
   title: "한강공원 반포 달빛 무지개 분수",
   category: "추천 여행지",
   image: Images.places.travel.rainbowPark,
   rating: 4.6,
   reviews: 230,
   price: "무료",
   location: "반포한강공원",
   tags: ["야경", "데이트", "산책"],
 },
 {
   id: 2,
   title: "숭실대 인근 봉천천 산책로",
   category: "추천 여행지",
   image: Images.places.travel.nakseongPark,
   rating: 4.3,
   reviews: 89,
   price: "무료",
   location: "봉천천",
   tags: ["산책", "운동", "자연"],
 },
 
 // 핫플
 {
   id: 4,
   title: "더 클라임 클라이밍",
   category: "핫플",
   image: Images.places.hotplaces.climbing,
   rating: 4.8,
   reviews: 142,
   price: "20,000원~",
   location: "숭실대입구역 3번출구",
   tags: ["운동", "클라이밍", "데이트"],
 },
 {
   id: 5,
   title: "상도동 루프탑 카페",
   category: "핫플",
   image: Images.places.hotplaces.rooftopCafe,
   rating: 4.7,
   reviews: 198,
   price: "5,000원~",
   location: "상도동",
   tags: ["카페", "루프탑", "뷰"],
 },
 
 // 맛집
 {
   id: 7,
   title: "스타벅스 숭실대점",
   category: "맛집",
   image: Images.places.restaurants.starbucks,
   rating: 4.8,
   reviews: 328,
   price: "4,500원~",
   location: "숭실대입구역 1번출구",
   tags: ["카페", "스터디", "커피"],
 },
 {
   id: 9,
   title: "베트남 쌀국수 퍼민",
   category: "맛집",
   image: Images.places.restaurants.phoMin,
   rating: 4.6,
   reviews: 278,
   price: "8,000원~",
   location: "숭실대입구역 4번출구",
   tags: ["베트남음식", "쌀국수", "분짜"],
 },
 
 // 생활용품
 {
   id: 11,
   title: "올리브영 숭실대입구역점",
   category: "생활용품",
   image: Images.places.shopping.oliveYoung,
   rating: 4.6,
   reviews: 234,
   price: "가격다양",
   location: "상도동",
   tags: ["화장품", "생활용품", "뷰티"],
 },
 {
   id: 12,
   title: "현대할인마트",
   category: "생활용품",
   image: Images.places.shopping.hyundaiMart,
   rating: 4.4,
   reviews: 156,
   price: "가격다양",
   location: "상도동",
   tags: ["마트", "식품", "생활용품"],
 },
 
 // 부동산 매물 (서초)
 {
   id: 13,
   title: "서초동 신축 원룸",
   category: "부동산 매물",
   region: "seocho",
   image: Images.places.realEstate.rooms[0],
   rating: 4.7,
   reviews: 45,
   price: "보증금 1천만원/월세 65만원",
   location: "서초동",
   tags: ["신축", "원룸", "풀옵션"],
 },
 {
   id: 14,
   title: "서초 역세권 투룸",
   category: "부동산 매물",
   region: "seocho",
   image: Images.places.realEstate.rooms[1],
   rating: 4.5,
   reviews: 32,
   price: "보증금 2천만원/월세 85만원",
   location: "서초동",
   tags: ["역세권", "투룸", "주차가능"],
 },
 
 // 부동산 매물 (동작)
 {
   id: 15,
   title: "상도동 리모델링 원룸",
   category: "부동산 매물",
   region: "dongjak",
   image: Images.places.realEstate.rooms[2],
   rating: 4.6,
   reviews: 28,
   price: "보증금 500만원/월세 45만원",
   location: "상도동",
   tags: ["리모델링", "원룸", "즉시입주"],
 },
 {
   id: 16,
   title: "상도동 대학가 원룸",
   category: "부동산 매물",
   region: "dongjak",
   image: Images.places.realEstate.rooms[3],
   rating: 4.4,
   reviews: 37,
   price: "보증금 1천만원/월세 50만원",
   location: "상도동",
   tags: ["대학가", "원룸", "풀옵션"],
 },
 
 // 부동산 매물 (신림)
 {
   id: 17,
   title: "신림동 반지하 원룸",
   category: "부동산 매물",
   region: "silim",
   image: Images.places.realEstate.rooms[4],
   rating: 4.2,
   reviews: 25,
   price: "보증금 300만원/월세 35만원",
   location: "신림동",
   tags: ["반지하", "원룸", "저렴"],
 },
 {
   id: 18,
   title: "신림 고시촌 원룸",
   category: "부동산 매물",
   region: "silim",
   image: Images.places.realEstate.rooms[5],
   rating: 4.3,
   reviews: 41,
   price: "보증금 500만원/월세 40만원",
   location: "신림동",
   tags: ["고시촌", "원룸", "조용"],
 }
];