// src/components/TMM/Map/Modal.tsx

import React from 'react';
import { Data } from './Data/datas';

interface ModalProps {
  onClose: () => void;
  data: Data;
}

const Modal: React.FC<ModalProps> = ({ onClose, data }) => {
  return (
    <div style={styles.overlay} onClick={onClose}>
      <div style={styles.modal} onClick={(e) => e.stopPropagation()}>
        <button style={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <h2>{data.name}</h2>
        <p>{data.description}</p>
        <p>{data.rating}</p>
        {/* Add more content as needed */}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    background: '#fff',
    padding: '20px',
    borderRadius: '8px',
    position: 'relative',
    width: '300px',
    maxWidth: '80%',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '15px',
    background: 'transparent',
    border: 'none',
    fontSize: '1.5rem',
    cursor: 'pointer',
  },
};

export default Modal;
