// src/components/TMM/Map/MarkersContainer.tsx

import React from 'react';
import Marker from './Marker';
import { datas, Data } from './Data/datas';

interface MarkersContainerProps {
  map: naver.maps.Map;
  onMarkerClick: (data: Data) => void;

}

const MarkersContainer: React.FC<MarkersContainerProps> = ({ map, onMarkerClick }) => {
    return (
    <>
      {datas.map((data: Data) => (
        <Marker
          key={data.id}
          map={map}
          position={data.position}
          title={data.name}
          icon={data.icon}
          onClick={() => onMarkerClick(data)}
        />
      ))}
    </>
  );
};

export default MarkersContainer;
