// src/components/TMM/TMM.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import { Banner } from './components/Banner';
import { CategoryList } from './components/CategoryList';
import { PlaceGrid } from './components/PlaceGrid';
import { MOCK_BANNERS, MOCK_PLACES } from './constants';
import Maps from './Map/Maps';
import ImageUpload from './ImageUpload';

const TMM: React.FC = () => {
  const [currentBanner, setCurrentBanner] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("전체");
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null);
  const [likedItems, setLikedItems] = useState<Set<number>>(new Set());
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);  // 자동 재생 상태

  const handleNextBanner = useCallback(() => {
    setCurrentBanner(prev => 
      prev === MOCK_BANNERS.length - 1 ? 0 : prev + 1
    );
  }, []);

  const handlePrevBanner = useCallback(() => {
    setCurrentBanner(prev => 
      prev === 0 ? MOCK_BANNERS.length - 1 : prev - 1
    );
  }, []);

  // 자동 슬라이드 효과
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isAutoPlaying) {
      intervalId = setInterval(() => {
        handleNextBanner();
      }, 5000);  // 5초마다 다음 배너로 이동
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isAutoPlaying, handleNextBanner]);

  // 마우스가 배너 위에 있을 때 자동 재생 일시 정지
  const handleMouseEnter = () => {
    setIsAutoPlaying(false);
  };

  // 마우스가 배너에서 벗어날 때 자동 재생 재개
  const handleMouseLeave = () => {
    setIsAutoPlaying(true);
  };

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
    if (category !== "부동산 매물") {
      setSelectedSubCategory(null);
    }
  };

  const handleSubCategorySelect = (subCategory: string) => {
    setSelectedSubCategory(subCategory);
  };

  const handleBannerClick = (index: number) => {
    setCurrentBanner(index);
  };

  const handleLike = (itemId: number) => {
    setLikedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(itemId)) {
        newSet.delete(itemId);
      } else {
        newSet.add(itemId);
      }
      return newSet;
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Banner
          currentBanner={currentBanner}
          banners={MOCK_BANNERS}
          onPrevClick={handlePrevBanner}
          onNextClick={handleNextBanner}
          onBannerClick={handleBannerClick} 
        />
      </Box>
      <Box sx={{ mt: 6 }}>  {/* 카테고리 리스트를 감싸는 Box 추가 */}
        <CategoryList
          selectedCategory={selectedCategory}
          selectedSubCategory={selectedSubCategory}
          onCategorySelect={handleCategorySelect}
          onSubCategorySelect={handleSubCategorySelect}
        />
      </Box>
      <PlaceGrid
        places={MOCK_PLACES}
        selectedCategory={selectedCategory}
        selectedSubCategory={selectedSubCategory}
        likedItems={likedItems}
        onLike={handleLike}
      />
      <Box sx={{ mt: 4 }}> {/* Optional: Add spacing before ImageUpload */}
        <ImageUpload /> {/* Moved inside the returned JSX */}
      </Box>
      <Box sx={{ mt: 4 }}> 
        <Maps />
      </Box>
    </Box>
  );

};

export default TMM;