//src/components/Header/Header.tsx

import React from 'react';
import logoImg from '@assets/logo_2.png';
import translateIcon from '@assets/translate_icon.png';
import { useTranslation } from 'react-i18next';
import useTranslationStore from '@store/translationStore';



import HeaderSearch from './HeaderSearch';
import { AppBar, Toolbar, IconButton, Box, } from '@mui/material';


interface HeaderProps {
  logoAlt?: string;
}

const Header: React.FC<HeaderProps> = ({ logoAlt = "Logo" }) => {
  const { currentLanguage, setTranslation } = useTranslationStore();
  const { i18n} = useTranslation();

    const handleTranslateToggle = () => {
      const languages = ['org', 'en', 'ko', 'zh', 'ja', 'vi'];

      const currentIndex = languages.indexOf(i18n.language);
      const nextIndex = (currentIndex + 1) % languages.length;
      const nextLanguage = languages[nextIndex];
      i18n.changeLanguage(languages[nextIndex]);
      
      setTranslation(nextLanguage);
    };


    return (
        <AppBar position="static" color="default" sx={{ boxShadow: 0 }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <img src={logoImg} alt={logoAlt} style={{ height: 40, marginRight: '1em' }} />

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <HeaderSearch />
                    <IconButton color="inherit" onClick={handleTranslateToggle} sx={{ ml: 2 }}>
                        <img src={translateIcon} alt="Translate" style={{ width: 24, height: 24 }} />
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    );
  };
  

export default Header;
