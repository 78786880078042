// src/components/ChatRoom//MessageInput.tsx
import React, { useState } from 'react';
import './MessageInput.css';
import useSocketStore from '@store/socketStore'; 
import useChatStore from '@store/chatStore';


const MessageInput: React.FC = () => {
  const { socket } = useSocketStore(); 
  const [message, setMessage] = useState<string>('');
  const chatUsername = useChatStore((state) => state.chatUsername);
  const currentRoomId = useChatStore((state) => state.currentRoomId);

  const sendMessage = () => {
    if (message.trim() && currentRoomId && chatUsername.trim()) {
      const messageData = {
        chatUsername: chatUsername.trim(),
        content: message.trim(),
        room_id: currentRoomId,
      };
      socket!.emit('send_message', messageData);
      setMessage('');
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className="message-input">
      <textarea
        placeholder="Type a message..."
        value={message}
        onChange={(e) => setMessage(e.currentTarget.value)}
        onKeyUp={handleKeyUp}
      />
      <button onClick={sendMessage}>Send</button>
    </div>
  );
};


export default MessageInput;
   