// src/components/TMM/utils/images.ts
const importImage = (path: string) => {
    return require(`@assets/${path}`);
  };
  
  export const Images = {
    banners: {
      restaurant: importImage('BannerFoodRank.svg'),
      realEstate: importImage('BannerRealEstate.svg'),
    },
    places: {
      travel: {
        rainbowPark: importImage('places/BanpoRainbow.jpg'),
        nakseongPark: importImage('places/NakPark.jpg'),
      },
      hotplaces: {
        climbing: importImage('places/TheClimb.png'),
        rooftopCafe: importImage('places/SangdoCafe.png'),
      },
      restaurants: {
        starbucks: importImage('places/Starbucks.jpg'),
        phoMin: importImage('places/PeoMin.jpg'),
      },
      shopping: {
        oliveYoung: importImage('places/OliveYoungSoongsil.jpg'),
        hyundaiMart: importImage('places/HyunDaeMart.jpg'),
      },
      realEstate: {
        rooms: Array.from({ length: 6 }, (_, i) => 
          importImage(`places/OneRoom0${i + 1}.jpg`)
        ),
      },
    },
  };