// App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import useAuthStore from '@store/authStore';

import PostList from '@components/post/PostList';
import PostWrite from '@components/post/PostWrite';
import PostDetail from '@components/post/PostDetail';
import ChatRoom from '@components/ChatRoom/ChatRoom';
import RegisterForm from '@components/Register/RegisterForm';
import Profile from '@components/Profile/Profile'; 
import Support from '@components/Support/Support';
import LandingPage from '@components/LandingPage/LandingPage';
import FAQ from '@components/FAQ/FAQ';
import TMM from '@components/TMM/TMM';


import ProtectedRoute from './ProtectedRoute';
import MainLayout from './MainLayout';

const App: React.FC = () => {
  const { isAuthenticated } = useAuthStore();
  console.log('isAuthenticated:', isAuthenticated);
  return (
    <Router>
      {isAuthenticated ? (
        <ProtectedRoute>
          <MainLayout>
            <Routes>
              <Route path="/community" element={<PostList />} />
              <Route path="/community/write" element={<PostWrite />} />
              <Route path="/link" element={<ChatRoom />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/post/:id" element={<PostDetail />} />
              <Route path="/support" element={<Support />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/tmm" element={<TMM />} />
            </Routes>
          </MainLayout>
        </ProtectedRoute>
      ) : (
        <Routes>          
          <Route path="*" element={<LandingPage />} />
          <Route path="/register" element={<RegisterForm />} />

        </Routes>
      )}
    </Router>
  );
};

export default App;
