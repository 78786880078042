//src/components/post/PostWrite.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TitleNlpAnalyzer from './TitleNlpAnalyzer';
import HashtagExtractor from './HashtagExtractor';
import { NewPostType } from './types';
import { Chip, TextField, Autocomplete, Container, Typography, Button, Box,ToggleButton, ToggleButtonGroup,Paper,Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axiosInstance from '@utils/axiosInstance';


const PostWrite: React.FC = () => {
  const [post, setPost] = useState<NewPostType>({
    category: '',
    detected_category: '',
    title: '',
    contents: '',
    detected_language: '',
    language: '',
    translations: [],
    hashtags: [],
    extracted_hashtags: [],
    translated_hashtags: [],
  });

  const { t } = useTranslation(); 
  const navigate = useNavigate();
  const [engTranslatedTitle, setEngTranslatedTitle] = useState<string>('');
  const [engTranslatedContents, setEngTranslatedContents] = useState<string>('');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [detectedLanguage, setDetectedLanguage] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>(''); 


  useEffect(() => {
    const checkFormValidity = () => {
      const isValid = post.title.trim() !== '' && post.contents.trim() !== '' && post.category !== '' && post.language !== '';
      setIsFormValid(isValid);
    };

    checkFormValidity();
  }, [post.title, post.contents, post.category, post.language]);

  const handleTitleChange = (title: string) => {
    setPost({ ...post, title });
  };
  const handleContentsChange = (contents: string) => {
    setPost({ ...post, contents });
  };

  const handleHashtagChange = (event: any, newHashtags: string[]) => {
    setPost({ ...post, hashtags: newHashtags });
  };


  const handleLanguageChange = (language: string) => {
    setPost((prevPost) => ({
      ...prevPost,
      language: language,
    }));
    setDetectedLanguage(language);
  };

  const handleTitleAnalysis = (detectedLanguage: string, detectedCategory: string, engTranslatedTitle:string) => {
    setPost((prevPost) => ({
      ...prevPost,
      language: detectedLanguage,
      category: detectedCategory,
      detected_category: detectedCategory,
      detected_language: detectedLanguage,
    }));
    setEngTranslatedTitle(engTranslatedTitle);
    setDetectedLanguage(detectedLanguage);
  };

  const handleCategoryClick = (category: string) => {
    setPost((prevPost) => ({
      ...prevPost,
      detected_category: prevPost.category,
      category,
    }));
  };
  

  const handleLanguageClick = (language: string) => {
    handleLanguageChange(language);
  };

  const handleHashtagsExtracted = (translated_hashtags: string[], extractedHashtags: string[]) => {
    setPost({ ...post, hashtags: translated_hashtags, extracted_hashtags: extractedHashtags });
    // console.log('translated_hashtags:', translated_hashtags);
    // console.log('extractedHashtags:', extractedHashtags);
  };


  const handleContentsAnalyzed = (engTranslatedContents: string) => {
    setEngTranslatedContents(engTranslatedContents);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const postData = { ...post, 
        engTranslatedTitle: engTranslatedTitle, 
        engTranslatedContents: engTranslatedContents,
      };
      await axiosInstance.post('/api/posts/', postData);
      navigate('/community');
    } catch (error) {
      console.error('게시글을 생성하는 중에 오류가 발생했습니다:', error);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ') {
      event.preventDefault();
      setTimeout(() => {
        const trimmedInput = inputValue.trim();
        if (trimmedInput && !post.hashtags.includes(trimmedInput)) {
          setPost({ ...post, hashtags: [...post.hashtags, trimmedInput] });
          setInputValue(''); 
        }
        else{
          setInputValue('');
        }
      }, 0); 
    }
  };
  

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={2} sx={{ p: 4, borderRadius: 2 }}>

        
        <form onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ mb: 2 }}>
              <TitleNlpAnalyzer 
                title={post.title} 
                onTitleChange={handleTitleChange} 
                onTitleAnalysis={handleTitleAnalysis} 
              />

              <HashtagExtractor 
                contents={post.contents}
                onContentsChange={handleContentsChange} 
                onHashtagsExtracted={handleHashtagsExtracted}
                onContentsAnalyzed={handleContentsAnalyzed}
                detectedLanguage={detectedLanguage}
              />
            </Stack>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom sx={{ color: '#061E30' }}>
              {t('Category')}
            </Typography>
            <ToggleButtonGroup
              value={post.category}
              exclusive
              onChange={(_, newCategory) => handleCategoryClick(newCategory)}
              sx={{ mb: 2 }}
            >
              {['daily', 'academic', 'career'].map((category) => (
                <ToggleButton 
                  key={category} 
                  value={category}
                  sx={{
                    '&.Mui-selected': {
                      bgcolor: '#061E30',
                      color: 'white',
                      '&:hover': {
                        bgcolor: '#051825',
                      },
                    },
                  }}
                >
                 {t(`PostList.${category}`)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom sx={{ color: '#061E30' }}>
            {t('Language')}
            </Typography>
            <ToggleButtonGroup
              value={post.language}
              exclusive
              onChange={(_, newLanguage) => handleLanguageClick(newLanguage)}
              sx={{ mb: 2 }}
            >
              {['KO', 'EN', 'JA', 'ZH', 'FI', 'etc'].map((language) => (
                <ToggleButton 
                  key={language} 
                  value={language}
                  sx={{
                    '&.Mui-selected': {
                      bgcolor: '#061E30',
                      color: 'white',
                      '&:hover': {
                        bgcolor: '#051825',
                      },
                    },
                  }}
                >
                  {t(`PostWrite.${language}`)}

                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              value={post.hashtags}
              onChange={handleHashtagChange}
              inputValue={inputValue}
              onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
              renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip 
                    variant="outlined" 
                    label={option} 
                    {...getTagProps({ index })} 
                    key={index}
                    sx={{
                      borderColor: '#061E30',
                      color: '#061E30',
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t('Hashtags')}
                  onKeyDown={handleKeyDown}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: '#061E30',
                      },
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#061E30',
                    },
                  }}
                />
              )}
            />
          </Box>

          <Button
            type="submit"
            variant="contained"
            disabled={!isFormValid}
            sx={{
              bgcolor: '#061E30',
              color: 'white',
              '&:hover': {
                bgcolor: '#051825',
              },
              '&.Mui-disabled': {
                bgcolor: '#e0e0e0',
              },
            }}
          >
              {t('CREATE POST')}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default PostWrite;
