// src/components/Login/Modal.tsx
import React, { ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ModalProps {
  children: ReactNode; 
  show: boolean; 
  onClose: () => void; 
}

const Modal: React.FC<ModalProps> = ({ children, show, onClose }) => {
  return (
    <Dialog
      open={show}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: 'white',
          color: '#061E30',
          borderRadius: 3,
          padding: 3,
        },
      }}
    >
      {/* Modal Header */}
      <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: '#061E30' }}>
        <IconButton onClick={onClose} sx={{ color: '#061E30' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ py: 3 }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
