// Comments.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Comment from './Comment';
import CommentWrite from './CommentWrite';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import './Comments.css';

interface CommentType {
  id: number;
  username: string;
  contents: string;
  created_at: string; 
}

const Comments: React.FC<{ postId: number }> = ({ postId }) => {
  const [comments, setComments] = useState<CommentType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { t, i18n } = useTranslation(); // Get i18n from useTranslation

  const fetchComments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${postId}/comments`, {
        params: { lang: i18n.language } // Pass the current language
      });
      setComments(response.data);
    } catch (err) {
      setError('Failed to fetch comments.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComments();
  }, [postId, i18n.language]);

  const handleCommentAdded = () => {
    fetchComments();
  };

  if (loading) return <p>{t('Loading comments...')}</p>;
  if (error) return <p>{t(error)}</p>;


  return (
    <div className="comments">
      {comments.length === 0 ? (
        <p>{t('No comments yet.')}</p>
      ) : (
        comments.map(comment => (
          <Comment key={comment.id} comment={comment} />
        ))
      )}
      <CommentWrite postId={postId} onCommentAdded={handleCommentAdded} />
    </div>
  );
};

export default Comments;
