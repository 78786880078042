// src/components/FAQ/FaqData.ts
import { FaqCategory } from './types';

export const faqData: FaqCategory[] = [
 {
   category: "학사",
   items: [
     {
       question: "수강신청은 어떻게 하나요?",
       answer: "1. u-SAINT 포털에 로그인합니다.\n2. '수강/성적' 메뉴에서 '수강신청' 을 선택합니다.\n3. 원하는 과목을 검색하고 신청 버튼을 클릭합니다.\n4. 신청 완료 메시지를 확인합니다.\n\n* 수강신청 기간과 정정기간을 반드시 확인하세요.\n* 수강신청 전 수강꾸러미를 미리 준비하면 편리합니다."
     },
     {
       question: "성적 이의신청은 어떻게 하나요?",
       answer: "성적 이의신청 절차:\n\n1. u-SAINT 포털 로그인\n2. '수강/성적' 메뉴에서 '성적조회'\n3. 해당 과목의 '이의신청' 버튼 클릭\n4. 이의신청 사유 상세히 작성\n5. 제출 후 담당 교수님 검토 대기\n\n* 성적 이의신청 기간을 반드시 확인하세요.\n* 명확한 근거와 함께 정중하게 작성하세요."
     },
     {
       question: "학점포기 신청 방법이 궁금합니다.",
       answer: "학점포기 신청 방법:\n\n1. u-SAINT 포털 로그인\n2. '수강/성적' 메뉴에서 '학점포기 신청'\n3. 포기할 과목 선택\n4. 신청 사유 작성 및 제출\n\n주의사항:\n- 재학 중 최대 2과목까지만 가능\n- 필수과목은 학점포기 불가\n- 학점포기한 과목은 재수강 불가\n- 졸업 직전 학기까지만 신청 가능"
     },
     {
       question: "휴학 신청은 어떻게 하나요?",
       answer: "휴학 신청 절차:\n\n1. u-SAINT 포털 로그인\n2. '학적' 메뉴에서 '휴학신청'\n3. 휴학 종류 선택 (일반/군입대/질병)\n4. 필요 서류 제출\n - 일반휴학: 별도 서류 없음\n - 군입대휴학: 입영통지서\n - 질병휴학: 진단서\n\n* 등록금 납부 후 휴학 시 등록금 이월 가능\n* 휴학 가능 학기 수 확인 필수"
     }
   ]
 },
 {
   category: "생활",
   items: [
     {
       question: "기숙사 신청은 어떻게 하나요?",
       answer: "기숙사 신청 절차:\n\n1. 숭실대학교 레지던스홀 홈페이지 접속\n2. 신청 기간 확인 (매 학기 말)\n3. 온라인 신청서 작성\n - 희망 호실 타입 선택\n - 기숙사 생활 계획서 작성\n4. 선발 결과 확인\n\n선발 기준:\n- 거주지 거리\n- 성적\n- 생활 계획서\n\n* 기숙사비 납부 기간 반드시 확인"
     },
     {
       question: "학생식당 위치와 운영시간이 궁금합니다.",
       answer: "학생식당 정보:\n\n1. 학생회관 1층\n - 운영시간: 10:00-19:00\n - 메뉴: 일반식, 분식\n\n2. 학생회관 2층\n - 운영시간: 11:00-14:00\n - 메뉴: 특식, 양식\n\n3. 형남공학관 지하\n - 운영시간: 08:30-19:00\n - 메뉴: 일반식, 라면\n\n* 방학 중에는 운영시간이 변경될 수 있음"
     },
     {
       question: "도서관 이용 시간은 어떻게 되나요?",
       answer: "중앙도서관 이용시간:\n\n1. 일반열람실\n - 평일: 06:00-23:00\n - 주말: 07:00-23:00\n\n2. 자료실\n - 평일: 09:00-17:30\n - 주말: 휴실\n\n3. 그룹스터디룸\n - 평일: 09:00-17:30\n - 예약 필수\n\n* 시험기간에는 24시간 운영\n* 공휴일은 운영시간이 다를 수 있음"
     },
     {
       question: "외국인 학생 지원 센터는 어디 있나요?",
       answer: "외국인 학생 지원 센터 정보:\n\n1. 위치\n - 학생회관 1층 106호\n\n2. 운영시간\n - 평일: 09:00-17:00\n - 점심시간: 12:00-13:00\n\n3. 주요 서비스\n - 유학생 생활 상담\n - 한국어 지원\n - 비자 관련 안내\n - 문화 프로그램 운영\n\n4. 연락처\n - 전화: 02-XXX-XXXX\n - 이메일: global@soongsil.ac.kr\n\n* 상담 예약 시 더 원활한 서비스 가능"
     }
   ]
 },
 {
   category: "장학",
   items: [
     {
       question: "장학금 신청 방법을 알려주세요.",
       answer: "장학금 신청 절차:\n\n1. u-SAINT 포털 로그인\n2. '장학/등록' 메뉴에서 '장학금신청'\n3. 신청할 장학금 종류 선택\n4. 필요 서류 준비\n - 가족관계증명서\n - 성적증명서\n - 기타 장학금별 요구서류\n5. 신청서 작성 및 서류 제출\n\n* 장학금 별로 신청기간이 다르니 공지사항 필수 확인"
     },
     {
       question: "국가장학금 신청 자격이 궁금합니다.",
       answer: "국가장학금 신청 자격:\n\n1. 기본 요건\n - 대한민국 국적\n - 국내 대학 재학생\n - 직전학기 성적 80점(100점 만점) 이상\n - 소득분위 8분위 이하\n\n2. 제외 대상\n - 대학원생\n - 휴학생\n - 초과학기 학생\n\n* 자세한 사항은 한국장학재단 홈페이지 참고"
     },
     {
       question: "교내장학금 종류는 어떻게 되나요?",
       answer: "주요 교내장학금:\n\n1. 성적우수장학금\n - 직전학기 성적 기준\n - 학과별 선발\n\n2. 봉사장학금\n - 학생회 임원\n - 과대표 등\n\n3. 근로장학금\n - 교내 각 부서 근로\n - 월별 지급\n\n4. 면학장학금\n - 가계곤란 학생 대상\n - 매 학기 신청 필요\n\n* 상세 내용은 학교 홈페이지 장학제도 안내 참고"
     },
     {
       question: "외국인 학생 장학금은 어떤 것이 있나요?",
       answer: "외국인 학생 장학금 종류:\n\n1. TOPIK 장학금\n - TOPIK 4급 이상 취득 시\n - 성적 기준 충족 필요\n\n2. 성적우수 장학금\n - 직전학기 평점 3.5 이상\n - 학과별 선발\n\n3. 글로벌 장학금\n - 입학 시 성적 우수자\n - 자동 심사\n\n4. 교환학생 장학금\n - 협정교 파견 학생 대상\n - 별도 신청 필요\n\n신청 방법:\n1. u-SAINT 포털 접속\n2. 외국인 장학금 신청 메뉴\n3. 필요 서류 제출\n\n* 기한 내 신청 필수"
     }
   ]
 }
];