import React from 'react';
import { 
  Card, 
  CardMedia, 
  CardContent, 
  Typography, 
  Box, 
  IconButton, 
  Chip,
  Rating,
  styled
} from '@mui/material';
import { FavoriteBorder, Favorite, LocationOn } from '@mui/icons-material';
import { PlaceItem } from '../types';

interface PlaceCardProps {
  place: PlaceItem;
  isLiked: boolean;
  onLike: (id: number) => void;
}

const StyledCard = styled(Card)({
  position: 'relative',
  height: '100%',
  borderRadius: '12px',
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
});

const ImageContainer = styled(Box)({
  position: 'relative',
  paddingTop: '66.67%', // 3:2 비율
  backgroundColor: '#f5f5f5',
});

const StyledCardMedia = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const LikeButton = styled(IconButton)({
  position: 'absolute',
  top: 8,
  right: 8,
  backgroundColor: 'white',
  padding: '8px',
  '&:hover': {
    backgroundColor: 'white',
  },
});

export const PlaceCard: React.FC<PlaceCardProps> = ({ place, isLiked, onLike }) => (
  <StyledCard>
    <ImageContainer>
      <StyledCardMedia
        src={place.image}
        alt={place.title}
      />
      <LikeButton
        onClick={(e) => {
          e.preventDefault();
          onLike(place.id);
        }}
      >
        {isLiked ? <Favorite color="error" /> : <FavoriteBorder />}
      </LikeButton>
    </ImageContainer>

    <CardContent sx={{ p: 2 }}>
      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{ 
          fontSize: '1rem',
          fontWeight: 600,
          mb: 1,
          height: '3rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {place.title}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Rating 
          value={place.rating} 
          readOnly 
          size="small" 
          sx={{ mr: 1 }}
        />
        <Typography variant="body2" color="text.secondary">
          ({place.reviews})
        </Typography>
      </Box>

      <Typography 
        variant="body1" 
        color="primary" 
        sx={{ 
          fontWeight: 600,
          mb: 1 
        }}
      >
        {place.price}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <LocationOn 
          sx={{ 
            fontSize: 16, 
            color: 'text.secondary',
            mr: 0.5 
          }} 
        />
        <Typography 
          variant="body2" 
          color="text.secondary"
        >
          {place.location}
        </Typography>
      </Box>

      <Box 
        sx={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: 0.5,
          mt: 1 
        }}
      >
        {place.tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            size="small"
            variant="outlined"
            sx={{ 
              height: '24px',
              fontSize: '0.75rem' 
            }}
          />
        ))}
      </Box>
    </CardContent>
  </StyledCard>
);