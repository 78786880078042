//src/components/Register/useFormSubmit.ts

import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import axiosInstance from '@utils/axiosInstance';

interface FormData {
  username: string;
  email: string;
  password: string;
  nickname: string; 
  university?: string; 
}


interface UseFormSubmitReturn {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  isLoading: boolean;
  error: string | null;
  serverErrors: { [key: string]: string };
  setServerErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
}

const useFormSubmit = (initialFormData: FormData): UseFormSubmitReturn => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [serverErrors, setServerErrors] = useState<{ [key: string]: string }>({});
  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setServerErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setServerErrors({});

    try {
      await axiosInstance.post('/api/register/', formData);
      setFormData(initialFormData);
      navigate('/'); 
    } catch (err: any) {
      console.log('Error details:', err);
      if (err.response) {
        console.log('Axios error response:', err.response);
        if (err.response.data.errors) {
          setServerErrors(err.response.data.errors);
        } else if (err.response.data.error) {
          setError(err.response.data.error);
        } else {
          setError('An unexpected error occurred');
        }
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { formData, setFormData, handleInputChange, handleSubmit, isLoading, error, serverErrors, setServerErrors };
};



export default useFormSubmit;