//src/components/post/hashTagExtractor.tsx
import React, { useState } from 'react';
import axiosInstance from '@utils/axiosInstance';
import './HashtagExtractor.css';
import { useTranslation } from 'react-i18next'; 
import { TextField, CircularProgress, Typography } from '@mui/material';

interface HashtagExtractorProps {
    contents: string;
    onHashtagsExtracted: (hashtags: string[], engHashtags: string[]) => void;
    onContentsChange: (contents: string) => void;
    onContentsAnalyzed: (engTranslatedContents: string) => void;
    detectedLanguage: string;
  }
  
const HashtagExtractor: React.FC<HashtagExtractorProps> = ({ contents, onHashtagsExtracted, onContentsChange, onContentsAnalyzed, detectedLanguage }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation(); 

  const handleBlur = async () => {
    if (contents.trim()) {
      setLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.post('/api/contentsKE/analyze', { contents, detectedLanguage });
        // console.log('contentsKE')
        // console.log(response.data);
        const translated_hashtags = response.data.translatedHashtags ? response.data.translatedHashtags.split(',').map((hashtag: string) => hashtag.trim()) : [];
        const engTranslatedContents = response.data.engTranslatedContents;
        const extractedHashtags = response.data.extractedHashtags ? response.data.extractedHashtags.split(',').map((hashtag: string) => hashtag.trim()) : [];
        console.log('translated_hashtags:', translated_hashtags);
        onHashtagsExtracted(translated_hashtags, extractedHashtags); 
        onContentsAnalyzed(engTranslatedContents);

      } catch (error) {
        setError('키워드 추출에 실패했습니다.');
        console.error('키워드 추출 오류:', error);
      } finally {
        setLoading(false);
      }
    } else {
        onHashtagsExtracted([], []);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    onContentsChange(value);
  };
  return (
    <div>
      <TextField
        label={t('Contents')}
        value={contents}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        fullWidth
        multiline
        minRows={4}
        margin="dense"
      />
      {loading && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
          <CircularProgress size={20} style={{ marginRight: '8px' }} />
          <Typography variant="body2">{t('Extracting hashtags...')}</Typography>
        </div>
      )}
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default HashtagExtractor;
