// src/components/TTM/components/CategoryList.tsx
import React from 'react';
import { Box, Collapse } from '@mui/material';
import { CategoryButton } from '../styles';
import { CATEGORIES } from '../constants';
import { SubCategory, CategoryListProps } from '../types';

export const CategoryList: React.FC<CategoryListProps> = ({
  selectedCategory,
  selectedSubCategory,
  onCategorySelect,
  onSubCategorySelect
}) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ display: 'flex', gap: 2, overflowX: 'auto', pb: 2 }}>
        {CATEGORIES.map((category) => (
          <CategoryButton
            key={category.label}
            active={selectedCategory === category.label}
            onClick={() => onCategorySelect(category.label)}
          >
            {category.label}
          </CategoryButton>
        ))}
      </Box>

      {/* 부동산 매물 선택 시 서브 카테고리 표시 */}
      <Collapse in={selectedCategory === "부동산 매물"}>
        <Box sx={{ 
          display: 'flex', 
          gap: 1, 
          mt: 2, 
          ml: 2,
          overflowX: 'auto', 
        }}>
          {CATEGORIES.find(cat => cat.label === "부동산 매물")?.subCategories?.map((sub: SubCategory) => (
          <CategoryButton
            key={sub.id}
            active={selectedSubCategory === sub.id}
            onClick={() => onSubCategorySelect(sub.id)}
            sx={{ 
              fontSize: '0.9rem',
              padding: '4px 12px',
            }}
          >
            {sub.label}
          </CategoryButton>
))}
        </Box>
      </Collapse>
    </Box>
  );
};