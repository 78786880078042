// src/components/ChatRoom/MessageRow.tsx
import React, { useState, useEffect } from 'react';
import { MessageRowProps } from './types';
import { formatDate } from '@utils/formatDate';
import './MessageRow.css';
import VisibilityWrapper from './VisibilityWrapper';
import useAuthStore from '@store/authStore';




const MessageRow: React.FC<MessageRowProps> = ({ message, onVisibilityChange }) => {
  const auth_user_id = useAuthStore((state) => state.user?.id);

  const [visibilityRatio, setVisibilityRatio] = useState(1);
  const isOwn = message.user_id === auth_user_id;
  useEffect(() => {
    onVisibilityChange(message.id, visibilityRatio);
  }, [visibilityRatio, onVisibilityChange, message.id]);

  const handleVisibilityChange = (ratio: number) => {
    if (message.id !== undefined) {
    setVisibilityRatio(ratio);
    onVisibilityChange(message.id, ratio); 
    }

    

  };

  const style = {
    opacity: 0.5 + visibilityRatio * 0.5,
    filter: `blur(${(1 - visibilityRatio) * 2}px)`,
    transition: 'opacity 0.3s ease, filter 0.3s ease',
  };

  return (
    <VisibilityWrapper onVisibilityChange={handleVisibilityChange}>
      <div className={`message-wrapper ${isOwn ? 'own' : ''}`} style={style}>
        <div className="message">
          <div className="meta-info">
            <strong>{message.chatUsername}</strong>
            <span>{formatDate(message.timestamp!)}</span>
          </div>
          <div className="message-content">{message.content}</div>
        </div>
      </div>
    </VisibilityWrapper>
  );
};

export default MessageRow;

