// src/components/FAQ/FaqAccordion.tsx
import React from 'react';
import { Typography } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { FaqItem } from './types';
import { 
  StyledAccordion, 
  StyledAccordionSummary, 
  StyledAccordionDetails 
} from './styles';

interface FaqAccordionProps {
  item: FaqItem;
}

const FaqAccordion: React.FC<FaqAccordionProps> = ({ item }) => (
  <StyledAccordion>
    <StyledAccordionSummary
      expandIcon={
        <ExpandMoreIcon sx={{ 
          fontSize: '24px',
          color: 'primary.main' 
        }} />
      }
    >
      <Typography 
        sx={{ 
          fontSize: '16px', 
          fontWeight: 600,
          color: 'rgba(0, 0, 0, 0.87)' 
        }}
      >
        {item.question}
      </Typography>
    </StyledAccordionSummary>
    <StyledAccordionDetails>
      <Typography
        component="pre"
        sx={{
          fontFamily: 'inherit',
          whiteSpace: 'pre-wrap',
          margin: 0,
          fontSize: '15px',
          lineHeight: 1.6,
          color: 'rgba(0, 0, 0, 0.7)'
        }}
      >
        {item.answer}
      </Typography>
    </StyledAccordionDetails>
  </StyledAccordion>
);

export default React.memo(FaqAccordion);