// src/components/FAQ/styles.ts


import { HelpCenterOutlined } from "@mui/icons-material";
import { styled } from "@mui/material";
import { Card, Box, Tabs, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 800,
  margin: "32px auto",
  borderRadius: "24px",
  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.04)",
  border: "1px solid rgba(0, 0, 0, 0.08)",
}));

export const StyledHeader = styled(Box)(({ theme }) => ({
  padding: "32px 32px 24px 32px",
  display: "flex",
  alignItems: "center",
  gap: "12px",
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTab-root': {
    textTransform: 'none',
    fontSize: '15px',
    fontWeight: 600,
    minWidth: 'auto',
    padding: '8px 20px',
    borderRadius: '12px',
    margin: '0 4px',
    color: 'rgba(0, 0, 0, 0.6)',
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.main}10`,
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: '16px !important',
  margin: '8px 0',
  border: '1px solid rgba(0, 0, 0, 0.08)',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: '8px 0',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    padding: '0 24px',
    '& .MuiAccordionSummary-content': {
      margin: '16px 0',
    },
    '&.Mui-expanded': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  }));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: '24px',
  backgroundColor: 'rgba(0, 0, 0, 0.01)',
}));

export const StyledHelpIcon = styled(HelpCenterOutlined)(({ theme }) => ({
    fontSize: 28,
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}15`,
    borderRadius: '50%',
    padding: '8px',
    '&:hover, &:focus': {
      backgroundColor: `${theme.palette.primary.main}15`,
      outline: 'none',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: `${theme.palette.primary.main}15`,
      boxShadow: 'none',
    },
    '&.MuiSvgIcon-root': {
      '&.Mui-focusVisible': {
        outline: 'none',
        boxShadow: 'none',
      },
      '&:active': {
        outline: 'none',
        boxShadow: 'none',
      },
    },
  }));