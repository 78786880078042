// src/components/Register/useValidation.ts
import { useState } from 'react';
import axiosInstance from '@utils/axiosInstance'; 
import { useTranslation } from 'react-i18next'; 

const useValidation = () => {
  const { t } = useTranslation(); 
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [validationStatus, setValidationStatus] = useState<{ [key: string]: boolean }>({});

  const updateState = (field: string, isValid: boolean, message?: string) => {
    setValidationStatus(prev => ({ ...prev, [field]: isValid }));
    setErrors(prev => ({ ...prev, [field]: message || '' }));
  };

  const validators = {
    username: (value: string) => value.length >= 2 || t('RegisterPage.Username must be at least 2 characters long.'),
    nickname: (value: string) => value.length >= 2 || t('RegisterPage.Nickname must be at least 2 characters long.'), 
    email: (value: string) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) || t('RegisterPage.Invalid email format.'),
    password: (value: string) => value.length >= 4 || t('RegisterPage.Password must be at least 4 characters long.'),
  };

  const checkDuplication = async (field: 'username' | 'email' | 'password' | 'nickname', value: string) => {
    if (!value.trim()) return updateState(field, false, t('RegisterPage.This field is required.'));

    const validationMessage = validators[field] && validators[field](value);
    if (typeof validationMessage === 'string') return updateState(field, false, validationMessage);

    if (field === 'password') {
      return updateState(field, true);
    }

    try {
      const response = await axiosInstance.get(
        `/api/register/check-duplication?${field}=${encodeURIComponent(value)}`
      );

      const isDuplicate = response.data.isDuplicate;
      isDuplicate 
      ? updateState(field, false, t(`RegisterPage.${field} is already in use.`)) 
      : updateState(field, true);
    } catch (error) {
      console.error(`Error checking duplication for ${field}:`, error);
      updateState(field, false, t('RegisterPage.Error occurred while checking duplication.'));
    }
  };

  return { errors, validationStatus, checkDuplication };
};

export default useValidation;
