
//src/components/Header/HeaderSearch.tsx
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {PostType} from '@post/types';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import { styled, Theme } from '@mui/material/styles';
import { useSearchContext } from '@contexts/SearchContext';

  const SearchGroup = styled('form')<{ isFocused: boolean }>(({ theme, isFocused }: { theme: Theme; isFocused: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('box-shadow'),
    boxShadow: isFocused ? theme.shadows[1] : 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }));


  const HeaderSearch: React.FC = () => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>(''); 
    const { t, i18n } = useTranslation();
    const { setSearchResults } = useSearchContext(); 

    const fetchPosts = useCallback(async (query: string) => {
        try {
            const response = await axios.get<PostType[]>(`${process.env.REACT_APP_BACKEND_URL}/api/posts/`, {
                params: {
                    search: query,
                    lang: i18n.language,
                },
            });
            setSearchResults(response.data);
            console.log(response.data);
        } catch (error) {
            console.error("Failed to fetch posts:", error);
        }
    }, [i18n.language, setSearchResults]);


    const handleSearch = useCallback((term: string) => {
        setSearchTerm(term);
        if (term.trim()) {
            fetchPosts(term);
        } else {
            setSearchResults([]);
        }
    }, [fetchPosts, setSearchResults]);

    const handleSearchSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSearch(searchTerm);
    }, [searchTerm, handleSearch]);

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    }, []);

    const handleFocus = useCallback(() => setIsFocused(true), []);
    const handleBlur = useCallback(() => setIsFocused(false), []);

    return (
        <SearchGroup isFocused={isFocused} onSubmit={handleSearchSubmit}>
            <InputBase
                placeholder={t('Search posts...')}
                inputProps={{ 'aria-label': 'search' }}
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                fullWidth
                sx={{ ml: 2 }}
            />
            <IconButton type="submit" aria-label="search">
                <SearchIcon />
            </IconButton>
        </SearchGroup>

    );
};


export default HeaderSearch;

