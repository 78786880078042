//src/components/TMM/ImageUpload.tsx

import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '@utils/axiosInstance';
import { Button, Box, Typography, CircularProgress, Alert } from '@mui/material';


interface OCRResult {
  extracted_text?: string;
  annotated_image?: string;
  error?: string;
}

const POLLING_INTERVAL = 2000; 

const ImageUpload: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [taskId, setTaskId] = useState<string | null>(null);
  const [status, setStatus] = useState<'idle' | 'starting' | 'uploading' | 'in_progress' | 'completed' | 'failed'>('idle');

  const [ocrResult, setOcrResult] = useState<OCRResult>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(null);
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (!file.type.startsWith('image/')) {
        alert('Please select a valid image file.');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        alert('File size exceeds 5MB limit.');
        return;
      }
      setSelectedFile(file);
    }
  };



  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select an image first.");
      return;
    }

    setStatus('starting');
    setOcrResult({});
    setErrorMessage(null);
    const formData = new FormData();
    formData.append('image', selectedFile);

    try {
      const response = await axiosInstance.post('/api/tmm/upload_receipt_image', formData);
      setTaskId(response.data.task_id);
      setStatus('in_progress');

    } catch (err: any) {
      console.error('Upload failed:', err);
      setStatus('failed');
      setErrorMessage(err.response?.data?.error || 'Upload request failed.');
    }
  };
  useEffect(() => {
    // If there's no taskId, no need to poll
    if (!taskId) return;

    const fetchResult = async () => {
      try {
        const response = await axiosInstance.get(`/api/tmm/task_result/${taskId}`);
        const data: OCRResult & { status: string } = response.data;

        if (data.status === 'processing') {
          setStatus('in_progress');
        } else if (data.status === 'ocr_completed') {
          setOcrResult({
            extracted_text: data.extracted_text,
            annotated_image: data.annotated_image,
          });
          setStatus('completed');
          clearPolling();
        } else if (
          data.status === 'download_failed' ||
          data.status === 'compression_failed' ||
          data.status === 'ocr_failed' ||
          data.status === 'db_failed'
        ) {
          setOcrResult({ error: data.error });
          setStatus('failed');
          clearPolling();
        } else if (data.status === 'no_text_detected') {
          setOcrResult({
            extracted_text: data.extracted_text,
            annotated_image: data.annotated_image,
          });
          setStatus('completed');
          clearPolling();
        }
      } catch (err: any) {
        console.error('Error fetching OCR result:', err);
        setErrorMessage('Failed to fetch OCR result.');
        setStatus('failed');
        clearPolling();
      }
    };

    // Start polling at defined intervals
    pollingIntervalRef.current = setInterval(fetchResult, POLLING_INTERVAL);

    // Initial fetch without waiting for the first interval
    fetchResult();

    // Cleanup function to clear the interval when component unmounts or taskId changes
    return () => {
      clearPolling();
    };
  }, [taskId]);

  const clearPolling = () => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }
  };



  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Image Upload for OCR
      </Typography>
      
      {/* File Input */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="image-upload-input"
        />
        <label htmlFor="image-upload-input">
          <Button variant="outlined" component="span">
            Choose Image
          </Button>
        </label>
        {selectedFile && (
          <Typography sx={{ ml: 2 }}>
            Selected File: {selectedFile.name}
          </Typography>
        )}
      </Box>
      
      {/* Upload Button */}
      <Button
        onClick={handleUpload}
        variant="contained"
        disabled={!selectedFile || status === 'in_progress' || status === 'uploading'}
      >
        {status === 'uploading' ? 'Uploading...' : 'Upload'}
      </Button>

      {/* Status Indicators */}
      {status === 'starting' || status === 'uploading' || status === 'in_progress' ? (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>
            {status === 'starting' && 'Starting upload...'}
            {status === 'uploading' && 'Uploading image...'}
            {status === 'in_progress' && 'Processing OCR...'}
          </Typography>
        </Box>
      ) : null}

      {/* Error Message */}
      {status === 'failed' && ocrResult.error && (
        <Box sx={{ mt: 4 }}>
          <Alert severity="error">Failed: {ocrResult.error}</Alert>
        </Box>
      )}

      {errorMessage && (
        <Box sx={{ mt: 4 }}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      )}

      {/* OCR Result */}
      {status === 'completed' && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            OCR Result:
          </Typography>
          {ocrResult.extracted_text && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Extracted Text:</Typography>
              <pre style={{ backgroundColor: '#f4f4f4', padding: '10px', whiteSpace: 'pre-wrap' }}>
                {ocrResult.extracted_text}
              </pre>
            </Box>
          )}
          {ocrResult.annotated_image && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">Annotated Image:</Typography>
              <img
                src={ocrResult.annotated_image}
                alt="Annotated"
                style={{ maxWidth: '100%', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ImageUpload;
