import React from 'react';

interface CurrentLocationButtonProps {
  onClick: () => void;
}

const CurrentLocationButton: React.FC<CurrentLocationButtonProps> = React.memo(({ onClick }) => {
    return (
      <button 
        onClick={onClick} 
        style={{ 
          padding: '8px 16px',
          backgroundColor: '#4A90E2',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '14px',
        }}
      >
        현재 위치 보기
      </button>
    );
  });
  
  export default CurrentLocationButton;
  