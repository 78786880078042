// src/store/authStore.ts

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface User {
  id: number;
  username: string;
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  validateAuthToken: () => Promise<void>;
  setAuthState: (authState: Partial<AuthState>) => void;
  logout: () => void;
}

const customLocalStorage = {
  getItem: (name: string) => {
    const value = localStorage.getItem(name);
    return value ? JSON.parse(value) : null;
  },
  setItem: (name: string, value: any) => {
    localStorage.setItem(name, JSON.stringify(value));
  },
  removeItem: (name: string) => {
    localStorage.removeItem(name);
  },
};


const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      isAuthenticated: false,
      setAuthState: (authState) => set((state) => ({ ...state, ...authState })),
      validateAuthToken: async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/login/validate`, {
            method: 'GET',
            credentials: 'include',
          });

          if (response.ok) {
            const data = await response.json();
            set({
              isAuthenticated: true,
              user: data.user,
            });
          } else {
            console.error(`Unexpected HTTP status: ${response.status}`);
            set({
              isAuthenticated: false,
              user: null,
            });
          }
        } catch (error) {
          console.error('Network error during token validation:', error);
          set({
            isAuthenticated: false,
            user: null,
          });
        }
      },
      logout: () => {
        sessionStorage.clear();
        localStorage.clear();      
        set({
          isAuthenticated: false,
          user: null,
        });
      },
    }),
    {
      name: 'auth-storage',
      storage: customLocalStorage,
    }
  )
);


export default useAuthStore;
