// src/components/post/TranslationEnhanceModal.tsx

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';


interface TranslationSuggestionModalProps {
  open: boolean;
  handleClose: () => void;
  postId: number;
  language: string;
  originalTitle: string;
  originalContents: string;
  translatedTitle: string;
  translatedContents: string;
}

const TranslationSuggestionModal: React.FC<TranslationSuggestionModalProps> = ({
  open,
  handleClose,
  postId,
  language,
  originalTitle,
  originalContents,
  translatedTitle,
  translatedContents,
}) => {
  const { t } = useTranslation();
  const [editedTitle, setEditedTitle] = useState<string>(translatedTitle);
  const [editedContents, setEditedContents] = useState<string>(translatedContents);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const handleSave = async () => {
    setLoading(true);
    try {
      setLoading(false);
      setSnackbarMessage(t('Translation updated successfully.'));
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleClose();
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(t('Failed to update translation.'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Failed to update translation:', error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>{t('Improve Translation')}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                {t('Original')}
              </Typography>
              <TextField
                label={t('Title')}
                value={originalTitle}
                fullWidth
                variant="outlined"
                InputProps={{ readOnly: true }}
                margin="normal"
              />
              <TextField
                label={t('Contents')}
                value={originalContents}
                fullWidth
                variant="outlined"
                multiline
                rows={15}
                InputProps={{ readOnly: true }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                {t('Translation')}
              </Typography>
              <TextField
                label={t('Title')}
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
              />
              <TextField
                label={t('Contents')}
                value={editedContents}
                onChange={(e) => setEditedContents(e.target.value)}
                fullWidth
                variant="outlined"
                multiline
                rows={15}
                margin="normal"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button
            onClick={handleSave}
            disabled={loading}
            variant="contained"
            color="primary"
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TranslationSuggestionModal;
