// src/components/TMM/Map/Data/datas.ts

export interface Data {
  id: number;
  name: string;
  position: {
    lat: number;
    lng: number;
  };
  rating: string;
  description: string;
  icon?: naver.maps.MarkerIconOptions;
}

export const datas: Data[] = [
  {
    id: 1,
    name: "미가 양꼬치",
    position: { lat: 37.494826, lng: 126.954218 },
    rating: "⭐️⭐️⭐️⭐️⭐️",
    description: "맛있는 양꼬치와 중국요리",
    icon: {
      content: `
        <div style="
          width: 24px;
          height: 24px;
          background: #FF6B6B;
          border: 2px solid white;
          border-radius: 50%;
          box-shadow: 0 2px 4px rgba(0,0,0,0.2);
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 14px;
        ">
          🍖
        </div>
      `,
      anchor: { x: 12, y: 12 }, 
    },
  },
];
