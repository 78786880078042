//src/components/TMM/Map/utils/loadNaverMaps.ts

let naverMapsLoaded = false;
let loadPromise: Promise<void> | null = null;

export const loadNaverMaps = (): Promise<void> => {
  if (naverMapsLoaded) {
    return Promise.resolve();
  }

  if (loadPromise) {
    return loadPromise;
  }

  loadPromise = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${process.env.REACT_APP_NAVER_MAP_CLIENT_ID}&submodules=geocoder`;
    script.async = true;
    script.onload = () => {
      naverMapsLoaded = true;
      resolve();
    };
    script.onerror = () => {
      console.error('Failed to load Naver Maps SDK.');
      reject(new Error('Failed to load Naver Maps SDK.'));
    };
    document.head.appendChild(script);
  });

  return loadPromise;
};
