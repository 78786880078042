import { DateTime } from 'luxon';

export function formatDate(dateString: string): string {
  // Parse the date string into a DateTime object in Korean time zone
  const postDateKST = DateTime.fromISO(dateString, { zone: 'Asia/Seoul' });

  // Get today's date in KST for comparison
  const nowKST = DateTime.now().setZone('Asia/Seoul');

  if (postDateKST.toISODate() === nowKST.toISODate()) {
    // If the post was made today (in KST), return in HH:MM format
    return postDateKST.toFormat('HH:mm');
  } else if (postDateKST.year === nowKST.year) {
    // If the post was made this year but not today, return in MM/DD format
    return postDateKST.toFormat('MM/dd');
  } else {
    // If the post was made in a previous year, return in YY/MM/DD format
    return postDateKST.toFormat('yy/MM/dd');
  }
}
