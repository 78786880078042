// src/utils/axiosInstance.ts
import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import Cookies from 'js-cookie';

const BASE_URL = process.env.REACT_APP_API_BASE_URL
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

const getCsrfToken = (): string | undefined => {
  return Cookies.get('csrf_access_token') || Cookies.get('csrf_refresh_token');
};

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const csrfToken = getCsrfToken();
    if (csrfToken) {
      config.headers.set('X-CSRF-TOKEN', csrfToken);
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);


axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;


// const axiosInstance = axios.create({
//   baseURL: process.env.REACT_APP_BACKEND_URL,
//   withCredentials: true, 
// });

// const csrfAxiosInstance = axios.create({
//     baseURL: process.env.REACT_APP_BACKEND_URL,
//     withCredentials: true, 
//   });

//   let csrfToken: string = '';

// const getCsrfToken = async (): Promise<string> => {
//   if (csrfToken) {
//     console.log('CSRF token already present:', csrfToken);
//     return csrfToken;
//   }
//   console.log('Fetching CSRF token from /api/csrf/get_csrf_token');
//   const response = await csrfAxiosInstance.get('/api/csrf/get_csrf_token');
//   csrfToken = response.data.csrf_token;
//   console.log('Fetched CSRF token:', csrfToken);
//   return csrfToken;
// };

// axiosInstance.interceptors.request.use(
//   async (config) => {
//     console.log('Interceptor triggered for request:', config.url);
//     const token = await getCsrfToken();
//     if (token && config.headers) {
//       config.headers['X-CSRF-Token'] = token;
//       console.log('Attached CSRF Token to request:', token);
//     }
//     return config;
//   },
//   (error) => {
//     console.error('Request interceptor error:', error);
//     return Promise.reject(error);
//   }
// );

// axiosInstance.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//       const originalRequest = error.config;
//       if (
//         error.response &&
//         error.response.status === 400 &&
//         error.response.data &&
//         error.response.data.msg &&
//         error.response.data.msg.includes('!!CSRF token missing!!')
//       ) {
//         originalRequest._retry = true;
//         csrfToken = '';
//         await getCsrfToken();
//         return axiosInstance(originalRequest);
//       }
//       return Promise.reject(error);
//     }
//   );

  

// export default axiosInstance;

// const getCsrfToken = async (): Promise<string> => {
//   const existingToken = Cookies.get('csrf_token');
//   if (existingToken) {
//     return existingToken;
//   }

//   const response = await axiosInstance.get('/api/get_csrf_token');
//   const csrfToken = response.data.csrf_token;
//   Cookies.set('csrf_token', csrfToken);
//   return csrfToken;
// };


// axiosInstance.interceptors.request.use(
//   async (config) => {
//     const csrfToken = Cookies.get('csrf_token') || (await getCsrfToken());
//     if (csrfToken && config.headers) {
//       config.headers['X-CSRFToken'] = csrfToken;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );


// axiosInstance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response && error.response.status === 403 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       await getCsrfToken();
//       return axiosInstance(originalRequest);
//     }
//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;
