//src/store/translationStore.ts

import { create } from 'zustand';

interface TranslationState {
  currentLanguage: string;
  setTranslation: (language: string) => void;
}

const useTranslationStore = create<TranslationState>((set) => ({
  currentLanguage: 'en',
  setTranslation: (language) => set({ 
    currentLanguage: language
  }),
}));


export default useTranslationStore;
