//src/layouts/MainLayout.tsx

import React from 'react';
import Sidebar from '@components/Sidebar';
import Header from '@header/Header';
import { SearchProvider } from '@contexts/SearchContext';
import { Box, Grid } from '@mui/material'; 


const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
    <SearchProvider>
      <Header />
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid item sx={{ width: '14rem' }}>
          <Sidebar />
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <Box sx={{ padding: '1rem' }}>
            {children}
          </Box>
        </Grid>
      </Grid>
    </SearchProvider>
  </Box>

);

export default React.memo(MainLayout);
