//src/components/TMM/Map/Marker.tsx

import React, { useEffect, useRef } from 'react';

interface MarkerProps {
  map: naver.maps.Map;
  position: { lat: number; lng: number };
  title?: string;
  icon?: naver.maps.MarkerIconOptions;
  onClick?: () => void;
}

const Marker: React.FC<MarkerProps> = ({ map, position, title, icon, onClick }) => {
  const markerRef = useRef<naver.maps.Marker | null>(null);
  
  useEffect(() => {
    if (map && window.naver && window.naver.maps) {
      const markerInstance = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(position.lat, position.lng),
        map,
        title,
        icon,
      });
      markerRef.current = markerInstance;

      if (onClick) {
        const listener = window.naver.maps.Event.addListener(markerInstance, 'click', onClick);
        return () => {
          window.naver.maps.Event.removeListener(listener);
        };
      }

      return () => {
        if (markerRef.current) {
          markerRef.current.setMap(null);
        }
      };
    }
  }, [map, position.lat, position.lng, title, icon, onClick]);

  return null;
};

export default Marker;
