// src/app/components/LandingPage/LandingPage.tsx

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoginModal from '../Login/LoginModal';
import {
  Box,
  Button,
  Container,
  Typography,
  Card,
  Grid,
  IconButton,
  Paper,
  FormControl,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SelectChangeEvent } from '@mui/material/Select';
import {
  Search,
  Translate,
  ChatBubble,
  SmartToy,
} from '@mui/icons-material';
import colors from '../../styles/colors';
import ReactCountryFlag from 'react-country-flag';
import Reactions from './Reactions';

// Styled Switch Component
const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#ff143f',
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
    backgroundColor: '#fff',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: 'background-color 0.2s ease',
  },
}));

// Styled Toggle Container
const ToggleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '8px 16px',
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  borderRadius: '12px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
  },
});

// Styled Toggle Text
const ToggleText = styled(Typography)({
  fontSize: '0.95rem',
  fontWeight: 500,
  color: colors.secondaryColor,
  userSelect: 'none',
});

const LandingPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHideContent, setIsHideContent] = useState(false);
  const [languageSelected, setLanguageSelected] = useState(false);
  const namespace = isHideContent ? 'LandingPage.Hide' : 'LandingPage.Origin';
  console.log('i18n.language:', i18n.language);
  const features = [
    {
      icon: <SmartToy />,
      title: t(`${namespace}.features.chatbotTitle`),
      description: t(`${namespace}.features.chatbotDesc`),
    },
    {
      icon: <Search />,
      title: t(`${namespace}.features.searchTitle`),
      description: t(`${namespace}.features.searchDesc`),
    },
    {
      icon: <Translate />,
      title: t(`${namespace}.features.multiLanguageTitle`),
      description: t(`${namespace}.features.multiLanguageDesc`),
    },
    {
      icon: <ChatBubble />,
      title: t(`${namespace}.features.communityTitle`),
      description: t(`${namespace}.features.communityDesc`),
    },
  ];

  const languages: Array<{ code: string; label: string; countryCode: string }> = [
    { code: 'en', label: t('language.english'), countryCode: 'US' },
    { code: 'ja', label: t('language.japanese'), countryCode: 'JP' },
    { code: 'ko', label: t('language.korean'), countryCode: 'KR' },
    { code: 'vi', label: t('language.vietnamese'), countryCode: 'VN' },
    { code: 'zh', label: t('language.chinese'), countryCode: 'CN' },
  ];

  const handleChangeLanguage = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value);
    setLanguageSelected(true);

  };

  const handleToggleContent = () => {
    setIsHideContent((prev) => !prev);
  };

  const selectedLanguage = languages.find(lang => lang.code === i18n.language) || languages[0];

  const renderToggle = () => (
    <ToggleContainer>
      <ToggleText>
        {t('LandingPage.toggleContent')}
      </ToggleText>
      <StyledSwitch
        checked={isHideContent}
        onChange={handleToggleContent}
        inputProps={{ 'aria-label': 'toggle content' }}
      />
    </ToggleContainer>
  );

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'grey.50', position: 'relative' }}>
      {/* Header with Language Selector */}
      <Box 
        sx={{ 
          position: 'absolute',
          top: 16,
          right: 16,
          zIndex: 1000
        }}
      >
        <FormControl variant="outlined" size="small">
          <Select
            value={i18n.language}
            onChange={handleChangeLanguage}
            sx={{
              bgcolor: 'white',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent'
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent'
              },
              boxShadow: 1,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              minWidth: 120
            }}
            renderValue={() => (
              languageSelected ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ReactCountryFlag 
                    countryCode={selectedLanguage.countryCode} 
                    svg 
                    style={{ width: '20px', height: '20px' }} 
                    title={selectedLanguage.label}
                  />
                  <Typography sx={{ ml: 1 }}>{selectedLanguage.label}</Typography>
                </Box>
              ) : (
                <Typography>{t('LandingPage.selectLanguage')}</Typography>
              )
            )}
          >
            {languages.map((lang) => (
              <MenuItem key={lang.code} value={lang.code}>
                <ListItemIcon>
                  <ReactCountryFlag 
                    countryCode={lang.countryCode} 
                    svg 
                    style={{ width: '20px', height: '20px' }} 
                    title={lang.label}
                  />
                </ListItemIcon>
                <ListItemText>{lang.label}</ListItemText>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Toggle Button */}
      <Box 
        sx={{ 
          position: 'absolute',
          top: 16,
          left: 16,
          zIndex: 1000
        }}
      >
        {renderToggle()}
      </Box>

      {/* Hero Section */}
      <Paper
        elevation={0}
        sx={{
          background: `linear-gradient(45deg, ${colors.mainColor} 30%, #030F18 90%)`,
          color: 'white',
          pt: 12,
          pb: 8,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={12}>
              <Typography 
                variant="h2" 
                component="h1"
                sx={{ 
                  fontWeight: 'bold',
                  mb: 3,
                  fontSize: { xs: '2.5rem', md: '3.5rem' }
                }}
              >
                {t(`${namespace}.welcome`)}
              </Typography>
              <Typography 
                variant="h5" 
                sx={{ 
                  mb: 4,
                  color: 'rgba(255, 255, 255, 0.9)',
                  lineHeight: 1.6
                }}
              >
                {t(`${namespace}.subtitle`)}
              </Typography>
              <Button
                variant="contained"
                size="large"
                onClick={() => setShowLoginModal(true)}
                sx={{
                  bgcolor: 'white',
                  color: colors.secondaryColor,
                  '&:hover': {
                    bgcolor: 'grey.100',
                  },
                  px: 4,
                  py: 1.5,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  boxShadow: 3
                }}
              >
                {t(`${namespace}.joinCommunity`)}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ py: 12 }}>
        <Typography
          variant="h3"
          component="h2"
          textAlign="center"
          gutterBottom
          sx={{ 
            mb: 8,
            fontWeight: 'bold',
            color: colors.secondaryColor
          }}
        >
          {t(`${namespace}.featuresTitle`)}
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 4,
                  textAlign: 'center',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: 6,
                  },
                  borderRadius: 2,
                  overflow: 'hidden'
                }}
              >
                <IconButton
                  sx={{
                    bgcolor: colors.mainColor,
                    color: 'white',
                    mb: 3,
                    p: 2,
                    '&:hover': {
                      bgcolor: colors.accentColor,
                    },
                    transition: 'all 0.3s ease',
                    '& .MuiSvgIcon-root': {
                      fontSize: '2rem',
                    }
                  }}
                >
                  {feature.icon}
                </IconButton>
                <Typography 
                  variant="h5" 
                  component="h3" 
                  gutterBottom
                  sx={{ 
                    fontWeight: 600,
                    color: colors.secondaryColor
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography 
                  color="text.secondary"
                  sx={{ 
                    lineHeight: 1.6,
                    flex: 1
                  }}
                >
                  {feature.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* About Section */}
      <Box 
        sx={{ 
          bgcolor: 'white',
          py: 12,
          borderTop: '1px solid',
          borderColor: 'grey.100'
        }}
      >
        <Container maxWidth="md">
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            textAlign="center"
            sx={{ 
              mb: 4,
              fontWeight: 'bold',
              color: colors.secondaryColor
            }}
          >
            {t(`${namespace}.aboutTitle`)}
          </Typography>
          <Typography
            variant="body1"
            paragraph
            textAlign="center"
            sx={{ 
              fontSize: '1.1rem',
              lineHeight: 1.8,
              color: 'text.secondary',
              whiteSpace: 'pre-line'
            }}
          >
            {t(`${namespace}.aboutText`)}
          </Typography>

          {i18n.language.substring(0, 2) === 'ko' && isHideContent && (
            <>
            <Reactions />
            <Box sx={{mb: 20}}></Box>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                textAlign="center"
                sx={{ 
                  mb: 4,
                  fontWeight: 'bold',
                  color: colors.secondaryColor
                }}
              >
                {t(`${namespace}.RUMKN`)}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                textAlign="center"
                sx={{ 
                  fontSize: '1.1rem',
                  lineHeight: 1.8,
                  color: 'text.secondary',
                  whiteSpace: 'pre-line'
                }}
              >
                {t(`${namespace}.IMNOTMKN`)}
              </Typography>
            </>
          )}


        </Container>
      </Box>
      <Box
        sx={{
          bgcolor: 'white',
          py: 4,
          mt: 8,
          borderTop: '1px solid',
          borderColor: 'grey.200',
          textAlign: 'center',
        }}
      >
        <Divider
          sx={{
            mb: 2,
            width: '60%',
            mx: 'auto',
            bgcolor: 'grey.300',
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontSize: '0.9rem',
            color: 'grey.600',
          }}
        >
          {t(`${namespace}.contactMessage1`)}{" "}
          <Typography
            component="span"
            sx={{
              fontWeight: 500,
              color: colors.mainColor,
              textDecoration: 'underline',
            }}
          >
            {t(`${namespace}.contactInfo`)}
          </Typography>
          <br />
          {t(`${namespace}.contactMessage2`)}
        </Typography>
      </Box>

      {/* Login Modal */}
      {showLoginModal && (
        <LoginModal
          show={showLoginModal}
          onClose={() => setShowLoginModal(false)}
        />
      )}
    </Box>
    
  );
};

export default LandingPage;
