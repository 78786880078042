// src/components/ChatRoom/RoomList.tsx

import React, { useState } from 'react';
import './RoomsList.css';
import { CreateRoomResponse, Room } from './types';
import useSocketStore from '@store/socketStore';
import useChatStore from '@store/chatStore';


const RoomsList: React.FC = () => {
  const [newRoomName, setNewRoomName] = useState<string>('');
  const [error, setError] = useState<string>(''); 
  const { socket } = useSocketStore(); 
  const {rooms, currentRoomId, setCurrentRoomId, setIsMessagesLoaded,} = useChatStore();

  const handleRoomSelection = (room: Room) => {
    setCurrentRoomId(room.id);
    setIsMessagesLoaded(true);
  };

  const createRoom = () => {
    const trimmedName = newRoomName.trim();
    if (trimmedName) {
      const roomExists = rooms.some(room => room.name === trimmedName);
      if (roomExists) {
        setError('A room with this name already exists.');
        return;
      }
      socket!.emit('create_room', { roomName: trimmedName }, (response: CreateRoomResponse) => { 
        if (response && response.success) {
          setNewRoomName('');
          setError('');
          socket!.emit('request_rooms'); 
        } else {
          setError(response?.error || 'Failed to create room.');
        }
      });
    } else {
      setError('Room name cannot be empty.');
    }
  };


  return (
    <div className="rooms-grid">
      {rooms.length === 0 ? (
        <p>No rooms available.</p>
      ) : (
      rooms.map((room) => (
        <div
          key={room.id} 
          onClick={() => handleRoomSelection(room)}
          className={`room-card ${currentRoomId === room.id ? 'currentRoomId' : ''}`}
        >
          {room.name || 'Unnamed Room'}
        </div>
      ))
    )}

      <div className="new-room-card">
        <input
          type="text"
          placeholder="Enter room name..."
          value={newRoomName}
          onChange={(e) => setNewRoomName(e.target.value)}
        />
        <button onClick={createRoom}>Create Room</button>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default RoomsList;