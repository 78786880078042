// src/components/Support/Support.tsx

import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import useAuthStore from "@store/authStore";
import {
  Alert,
  AlertTitle,
  Button,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import {
  Message as MessageIcon,
  Send as SendIcon,
  Error as ErrorIcon,
  Delete as DeleteIcon,

} from "@mui/icons-material";

interface SupportChatMessage {
  sender: "user" | "model" | "System";
  text: string;
}

const MAX_MESSAGE_LENGTH = 100; 

const Support: React.FC = () => {
  const [inputText, setInputText] = useState<string>("");
  const [supportChatHistory, setSupportChatHistory] = useState<SupportChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isLimitExceeded, setIsLimitExceeded] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { user, isAuthenticated } = useAuthStore();

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [supportChatHistory, scrollToBottom]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchChatHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const fetchChatHistory = useCallback(async () => {
    if (!isAuthenticated) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/chatgeans/history`,
        {
          withCredentials: true,
        }
      );

      console.log(response);

      if (response.data.status === "success") {
        setSupportChatHistory(response.data.history);
      } else {
        setError(response.data.message || "Failed to load chat history.");
      }
    } catch (err) {
      console.error("Error fetching chat history:", err);
      setError("Failed to load chat history. Please try again later.");
    }
  }, [isAuthenticated]);

  const sendMessage = useCallback(async () => {
    if (!isAuthenticated || !inputText.trim() || isLoading || isLimitExceeded) return;

    setIsLoading(true);
    setError(null);

    const newMessage: SupportChatMessage = { sender: "user", text: inputText };
    setSupportChatHistory(prev => [...prev, newMessage]);
    setInputText("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/chatgeans`,
        { message: inputText }, // Assuming backend identifies user via cookies
        {
          withCredentials: true, // Include cookies
        }
      );

      if (response.data.status === "success") {
        const botMessage: SupportChatMessage = {
          sender: "model",
          text: response.data.reply,
        };
        setSupportChatHistory(prev => [...prev, botMessage]);
      } else if (response.status === 429 || response.data.status === "error") {
        setError(response.data.message || "Usage limit exceeded.");
        setSupportChatHistory(prev => [
          ...prev,
          { sender: "System", text: response.data.message || "Usage limit exceeded." },
        ]);
        setIsLimitExceeded(true);
      } else {
        setError(response.data.message || "An unexpected error occurred.");
        setSupportChatHistory(prev => [
          ...prev,
          { sender: "System", text: response.data.message || "An unexpected error occurred." },
        ]);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 429) {
        setError(error.response.data.message || "Usage limit exceeded.");
        setSupportChatHistory(prev => [
          ...prev,
          { sender: "System", text: error.response.data.message || "Usage limit exceeded." },
        ]);
        setIsLimitExceeded(true);
      } else {
        setError("Failed to send message. Please try again.");
        console.log("Error sending message:", error);
        setSupportChatHistory(prev => [
          ...prev,
          { sender: "System", text: "Failed to send message. Please try again." },
        ]);
      }
    } finally {
      setIsLoading(false);
    }
  }, [isAuthenticated, inputText, isLoading, isLimitExceeded]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        sendMessage();
      }
    },
    [sendMessage]
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= MAX_MESSAGE_LENGTH) {
      setInputText(e.target.value);
    }
  }, []);

  const handleDelete = useCallback(async () => {
    setDeleteDialogOpen(false);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/chatgeans/history`,
        {
          withCredentials: true, // Include cookies
        }
      );

      if (response.data.status === "success") {
        setSupportChatHistory([]);
        setError(null);
        setIsLimitExceeded(false);
      } else {
        setError(response.data.message || "Failed to delete chat history.");
      }
    } catch (err) {
      console.error("Error deleting chat history:", err);
      setError("Failed to delete chat history. Please try again later.");
    }
  }, []);


  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };



  if (!isAuthenticated) {
    return (
      <Card sx={{ maxWidth: 600, margin: "32px auto" }}>
        <CardContent>
          <Alert severity="error" icon={<ErrorIcon />}>
            <AlertTitle>Authentication Required</AlertTitle>
            Please log in to access the support chat.
          </Alert>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ maxWidth: 600, margin: "32px auto" }}>
      <CardHeader
        avatar={<MessageIcon />}
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h6" component="div">
              Support Chat
            </Typography>
          </Box>
        }
        action={
          <IconButton onClick={openDeleteDialog} disabled={supportChatHistory.length === 0}>
            <DeleteIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Box
          sx={{
            height: 1000,
            overflowY: "auto",
            mb: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {supportChatHistory.map((msg, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent={msg.sender === "user" ? "flex-end" : "flex-start"}
            >
              <Box
                sx={{
                  maxWidth: "80%",
                  p: 2,
                  borderRadius: 2,
                  backgroundColor:
                    msg.sender === "user"
                      ? "primary.main"
                      : msg.sender === "System"
                      ? "error.light"
                      : "grey.300",
                  color:
                    msg.sender === "user"
                      ? "primary.contrastText"
                      : msg.sender === "System"
                      ? "error.dark"
                      : "black",
                }}
              >
                <Typography variant="body1">{msg.text}</Typography>
              </Box>
            </Box>
          ))}
          <div ref={messagesEndRef} />
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} icon={<ErrorIcon />}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}

        {isLimitExceeded && (
          <Alert severity="warning" sx={{ mb: 2 }} icon={<ErrorIcon />}>
            <AlertTitle>Usage Limit Reached</AlertTitle>
            The support chat usage limit has been reached. Please try again later.
          </Alert>
        )}

        <Box display="flex" gap={2}>
          <TextField
            value={inputText}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder="Type your Question"
            variant="outlined"
            fullWidth
            disabled={isLoading || isLimitExceeded}
            multiline
            maxRows={4}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={sendMessage}
            disabled={
              !inputText.trim() || isLoading || isLimitExceeded
            }
            sx={{ minWidth: 100 }}
            endIcon={isLoading ? <CircularProgress size={20} /> : <SendIcon />}
          >
            {isLoading ? "Sending" : "Send"}
          </Button>
        </Box>
      </CardContent>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Chat History</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete all your chat history? This action cannot be undone, and you will need to start a new chat session.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};


export default Support;
