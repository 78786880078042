// src/components/ChatRoom/UsernameInput.tsx

import React from 'react';
import './UsernameInput.css';
import { debounce } from 'lodash';

interface UsernameInputProps {
  chatUsername: string;
  setChatUsername: (username: string) => void;
}

const UsernameInput: React.FC<UsernameInputProps> = ({ chatUsername, setChatUsername }) => {
  const [inputValue, setInputValue] = React.useState(chatUsername);
  const debouncedSetUsername = React.useCallback(
    debounce((value: string) => {
      setChatUsername(value);
    }, 500),
    [setChatUsername]
  );

  React.useEffect(() => {
    debouncedSetUsername(inputValue);
    
    return () => {
      debouncedSetUsername.cancel();
    };
  }, [inputValue, debouncedSetUsername]);

  return (
    <div className="username-input">
      <input
        type="text"
        placeholder="Enter your username..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </div>
  );
};


export default UsernameInput;
