// src/components/TMM/components/PlaceGrid.tsx
import React from 'react';
import { Grid } from '@mui/material';
import { PlaceCard } from './PlaceCard';
import { PlaceGridProps } from '../types';

export const PlaceGrid: React.FC<PlaceGridProps> = ({
  places,
  selectedCategory,
  selectedSubCategory,
  likedItems,
  onLike
}) => (
  <Grid container spacing={3}>
    {places
      .filter(place => {
        if (selectedCategory === "전체") return true;
        if (selectedCategory === "부동산 매물" && selectedSubCategory) {
          return place.category === selectedCategory && place.region === selectedSubCategory;
        }
        return place.category === selectedCategory;
      })
      .map((place) => (
        <Grid item xs={12} sm={6} md={4} key={place.id}>
          <PlaceCard
            place={place}
            isLiked={likedItems.has(place.id)}
            onLike={onLike}
          />
        </Grid>
      ))}
  </Grid>
);