// src/components/Profile/Profile.tsx

import React from 'react';
import useAuthStore from '@store/authStore';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '@utils/axiosInstance';

const Profile: React.FC = () => {
  const { user, isAuthenticated, logout } = useAuthStore(); 
  const navigate = useNavigate(); 

  const handleLogout = async () => {
    try {
      const response = await axiosInstance.post('/api/login/logout');
      if (response.status === 200) {
        logout(); 
        navigate('/'); 
      } else {
        console.error('Failed to logout');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="profile-container">
        <h2>User is not authenticated</h2>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <h1>Profile Page</h1>
      <div className="profile-info">
        <p><strong>Username:</strong> {user?.username}</p>
        {/* Additional user information */}
      </div>
      <button onClick={handleLogout} className="logout-button">Logout</button>
    </div>
  );
};

export default Profile;
