// src/store/chatStore.ts

import { create } from 'zustand';
import { Message, Room } from '@components/ChatRoom/types';
import { persist } from 'zustand/middleware';

interface ChatState {
    chatUsername: string;
    currentRoomId: number | null;
    rooms: Room[];
    messages: Message[];
    isLoadingRooms: boolean;
    isMessagesLoaded: boolean;
    setChatUsername: (chatUsername: string) => void;
    setCurrentRoomId: (roomId: number | null) => void;
    setRooms: (rooms: Room[]) => void;
    setMessages: (messages: Message[]) => void;
    addMessage: (message: Message) => void;
    setIsLoadingRooms: (isLoading: boolean) => void;
    setIsMessagesLoaded: (isLoaded: boolean) => void;
  }

  
  const customSessionStorage = {
    getItem: (name: string) => {
      const value = sessionStorage.getItem(name);
      return value ? JSON.parse(value) : null;
    },
    setItem: (name: string, value: any) => {
      sessionStorage.setItem(name, JSON.stringify(value));
    },
    removeItem: (name: string) => {
      sessionStorage.removeItem(name);
    },
  };
    
  
  const useChatStore = create<ChatState>()(
    persist(
        (set) => ({
          chatUsername: 'InitUserName',
          currentRoomId: null,
          rooms: [],
          messages: [],
          isLoadingRooms: true,
          isMessagesLoaded: false,
          setChatUsername: (chatUsername) => set({ chatUsername }),
          setCurrentRoomId: (roomId) => set({ currentRoomId: roomId }),
          setRooms: (rooms) => set({ rooms }),
          setMessages: (messages) => set({ messages }),
          addMessage: (message) =>
            set((state) => ({ messages: [...state.messages, message] })),
          setIsLoadingRooms: (isLoading) => set({ isLoadingRooms: isLoading }),
          setIsMessagesLoaded: (isLoaded) => set({ isMessagesLoaded: isLoaded }),
        }),
        {
          name: 'chat-storage',
          storage: customSessionStorage,
          partialize: (state) => ({
            chatUsername: state.chatUsername,
            currentRoomId: state.currentRoomId,
            isLoadingRooms: state.isLoadingRooms,
            isMessagesLoaded: state.isMessagesLoaded,
          }),
        }   
      )
    );
    
    
export default useChatStore;
